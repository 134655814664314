import { TemplateRef } from '@angular/core';
import { Alignment } from './enums/alignment.enum';

export interface TableDataItem {
  name: string;
  translationString: string;
  isClickable?: boolean;
  showNameInTableHeader?: boolean;
  isActionItems?: boolean;
  isStatusItem?: boolean;
  isUnassignButton?: boolean;
  width: number; // width in %,
  hasRoles?: boolean;
  isStyle?: boolean;
  hasIcon?: boolean;
  trimmed?: boolean; // true
  trimmedWidth?: number;
  hasMultipleIcons?: boolean;
  isLabelingItem?: boolean;
  isTempingItem?: boolean;
  forceClickable?: boolean;
  isEventInfo?: boolean;
  isSortDisabled?: boolean;
  isCheckbox?: boolean;
  isStacked?: boolean;
  stackHasIcon?: boolean;
  stackSecondRowName?: string;
  useTitleCase?: boolean; // true
  alignment?: Alignment; // Alignment.Left
  multiline?: boolean; // false
  hasMenuItemsInTableHeader?: boolean; // false
  tableHeaderActions?: string[]; //[]
  editableFieldType?: string;
  columnTemplate?: TemplateRef<any>;
  visible?: boolean; //= true
  sticky?: boolean; //false
  isClickableText?: boolean; //false
  emitsRowClickedEvent?: boolean;
  hasTooltip?: boolean;

  stackedColumns?: StackedColumn[];
  preCustomIconVisible?: boolean;
  useRedLock?: boolean;
  rendersMultipleRowProperties?: boolean;
  rowProperties?: string[];
}

const defaultData = {
  visible: true,
  trimmed: true,
  useTitleCase: true,
  alignment: Alignment.Left,
  tableHeaderActions: [],
  isClickable: true, // future enhancement this is currently used for cell clicked or showing the pointer for the whole row , we should a seperated property for the row cursor to view details
  showNameInTableHeader: true,
  emitsRowClickedEvent: true
};
export class TableDataItem implements TableDataItem {
  constructor(dataItem: TableDataItem) {
    const data: TableDataItem = { ...defaultData, ...dataItem };
    Object.assign(this, data);
  }
}

export class StackedColumn {
  constructor(public hasIcon?: boolean, public icon?: string, public iconTitle?: string, public rowName?: string, public isEditable?: boolean, public iconWidth: number = 10, public extraStyles?: {}) {}
}
