import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { APIResponseModel } from '../../shared/models/api-response';
import { HttpMethod } from '../../shared/models/enums/HttpMethod.enum';
import { LoaderService } from '../../shared/services/loader.service';
import { APIHttpResponseCode } from './../../shared/models/api-response';
import { ApiResponseCodeEnum } from './../../shared/models/enums/ApiResponseCode.enum';
import { SnackbarService } from './../../shared/services/snackbar.service';
import { AuthService } from './../auth/auth.service';
import { defined } from '../../shared/helpers/app.helpers';
import { OGGetLimits } from '../../modules/inventory-manager/constants/OG-table.constants';
import { CatalogResponseCodeEnum } from '../../modules/catalog-manager/validation/catalog-errors';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {
  private get publisherAccount(): boolean {
    return this.authService.getIsPublisher();
  }

  constructor(private loaderService: LoaderService, private authService: AuthService, private snackbarService: SnackbarService) {}

  handleError(err: any, url?: string, method?: string, hideWarningPopup: boolean = false): Observable<any> {
    this.loaderService.hideLoader();
    if (err.statusCode === APIHttpResponseCode.unauthorized && !this.authService.tokenBeingRefreshed.value) {
      this.authService.tokenBeingRefreshed.next(true);
      this.authService.refreshToken().subscribe(
        (response) => {
          this.authService.setAuthToken(response.results.token);
          this.authService.setRefreshToken(response.results.refreshToken);
          this.authService.tokenBeingRefreshed.next(false);
        },
        (error) => {
          this.authService.logout();
        }
      );
    } else {
      if (err.status === APIHttpResponseCode.forbidden) {
        this.snackbarService.openWarningSnackBar('error.NO_PERMISSION_ERROR', 'Ok');
      } else {
        this.displayError(<APIResponseModel<{ any }>>err, url, method, hideWarningPopup);
      }
    }
    return observableThrowError(err);
  }

  displayError(err: APIResponseModel<any>, url: string, method: string, hideWarningPopup: boolean = false) {
    // if we have common errors among services, check the url for the service name, so we can decide which error text to show.
    let errorCode: number;
    let details: any;
    if (err.results) {
      errorCode = err.results.code;
      details = err.results.data;
    }
    // const errorCode = err.results.code;
    let errorText = '';
    let interpolateParams = null;
    let showErrorMessage = !hideWarningPopup;
    let duration: number = 5000;
    switch (errorCode) {
      case ApiResponseCodeEnum.NO_RECORDS_FOUND:
        errorText = 'error.NO_RECORDS_FOUND';
        break;

      case ApiResponseCodeEnum.TENANT_NOT_SUBSCRIBED_TO_MENU:
        errorText = 'error.TENANT_NOT_SUBSCRIBED_TO_MENU';
        break;

      case ApiResponseCodeEnum.TENANT_REFRESH_TOKEN_LESS_THAN_USER_TOKEN:
        errorText = 'error.TENANT_REFRESH_TOKEN_LESS_THAN_USER_TOKEN';
        break;

      case ApiResponseCodeEnum.REFRESH_TOKEN_INVALID:
      case ApiResponseCodeEnum.REFRESH_TOKEN_EXPIRED_UNAUTHORIZED_ACCESS:
        errorText = 'error.REFRESH_TOKEN_INVALID';
        this.authService.logout();
        break;

      case ApiResponseCodeEnum.UNKNOWN_ERROR:
      case ApiResponseCodeEnum.UNKNOWN_ZY_ERROR:
        errorText = 'error.UNKNOWN_ERROR';
        break;

      // Menu Export Error
      case ApiResponseCodeEnum.MENU_EXPORT_ERROR:
        errorText = 'error.MENU_EXPORT_ERROR';
        break;

      // Menu Import Error
      case ApiResponseCodeEnum.MENU_IMPORT_ERROR:
      // Menu Import Duplicate Items Error
      case ApiResponseCodeEnum.MENU_IMPORT_DUPLICATE_ITEMS_ERROR:
      // Menu customization Import Error
      case ApiResponseCodeEnum.MENU_CUSTOMIZATION_IMPORT_ERROR:
      // Menu customization Import Duplicate Items Error
      case ApiResponseCodeEnum.MENU_CUSTOMIZATION_IMPORT_DUPLICATE_ITEMS_ERROR:
        errorText = 'error.MENU_IMPORT_ERROR';
        showErrorMessage = false;
        break;

      // Terminals Error Codes 200 - 399
      case ApiResponseCodeEnum.TERMINAL_ALREADY_ASSIGNED:
        errorText = 'error.TERMINAL_ALREADY_ASSIGNED';
        break;
      case ApiResponseCodeEnum.TERMINAL_INVALID:
        errorText = 'error.TERMINAL_INVALID';
        break;
      case ApiResponseCodeEnum.TERMINAL_SERIAL_NUMBER_AMBIGUOUS:
        errorText = 'error.TERMINAL_SERIAL_NUMBER_AMBIGUOUS';
        break;
      case ApiResponseCodeEnum.TERMINAL_DOES_NOT_EXIST:
        errorText = 'error.TERMINAL_DOES_NOT_EXIST';
        break;
      case ApiResponseCodeEnum.TERMINAL_NOT_ASSIGNED_TO_LOCATION:
        errorText = 'error.TERMINAL_NOT_ASSIGNED_TO_LOCATION';
        break;
      case ApiResponseCodeEnum.TERMINAL_STORE_HIERARCHY_PROBLEM:
        errorText = 'error.TERMINAL_STORE_HIERARCHY_PROBLEM';
        break;
      case ApiResponseCodeEnum.TERMINAL_INACTIVE:
        errorText = 'error.TERMINAL_INACTIVE';
        break;
      case ApiResponseCodeEnum.LOCATION_CODE_NOT_FOUND:
        errorText = 'error.LOCATION_CODE_NOT_FOUND';
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.ORDER_GUIDES_REQUIRED_FOR_ENABLING_ORDERS:
        errorText = 'error.ORDER_GUIDES_REQUIRED_FOR_ENABLING_ORDERS';
        break;

      //Invlaid old password
      case ApiResponseCodeEnum.INVALIDE_OLD_PASSWORD:
        errorText = 'error.INVALIDE_OLD_PASSWORD';
        break;

      // Locations and stores Error Codes 400 - 599
      case ApiResponseCodeEnum.FOLDER_NAME_ALREADY_EXISTS_UNDER_THIS_LOCATION:
        errorText = 'error.FOLDER_NAME_ALREADY_EXISTS_UNDER_THIS_LOCATION';
        break;
      case ApiResponseCodeEnum.STORE_NAME_ALREADY_EXISTS_UNDER_THIS_LOCATION:
        errorText = 'error.STORE_NAME_ALREADY_EXISTS_UNDER_THIS_LOCATION';
        break;
      case ApiResponseCodeEnum.STORE_NUMBER_ALREADY_EXISTS:
        errorText = 'error.STORE_NUMBER_ALREADY_EXISTS';
        break;
      case ApiResponseCodeEnum.MASTER_NODE_EXISTS:
        errorText = 'error.MASTER_NODE_EXISTS';
        break;
      case ApiResponseCodeEnum.LOCATION_CODE_ALREADY_EXISTS:
        errorText = 'error.LOCATION_CODE_ALREADY_EXISTS';
        break;
      case ApiResponseCodeEnum.ANCESTOR_STORE_ALREADY_EXIST:
        errorText = 'error.ANCESTOR_STORE_ALREADY_EXIST';
        break;
      case ApiResponseCodeEnum.LOCATION_CODE_NOT_VALID:
        errorText = 'error.LOCATION_CODE_NOT_VALID';
        break;
      case ApiResponseCodeEnum.LOCATION_CODE_NOT_EXISTS:
        errorText = 'error.LOCATION_CODE_NOT_EXISTS';
        break;
      case ApiResponseCodeEnum.INVALID_LOCATION_TYPE:
        errorText = 'error.INVALID_LOCATION_TYPE';
        break;
      case ApiResponseCodeEnum.STORE_INFO_UNDEFINED:
        errorText = 'error.STORE_INFO_UNDEFINED';
        break;
      case ApiResponseCodeEnum.FOLDER_CODE_ALREADY_EXISTS:
        errorText = 'error.FOLDER_CODE_ALREADY_EXISTS';
        break;
      case ApiResponseCodeEnum.STORE_NUMBER_ALREADY_EXISTS_IN_OTHER_ACCOUNT:
        errorText = 'error.STORE_NUMBER_ALREADY_EXISTS_IN_OTHER_ACCOUNT';
        break;
      case ApiResponseCodeEnum.FILE_IMPORT_LOCATION_STORE_NUMBER_ALREADY_EXISTS_In_OTHER_ACCOUNT:
        errorText = 'error.FILE_IMPORT_LOCATION_STORE_NUMBER_ALREADY_EXISTS_In_OTHER_ACCOUNT';
        break;
      case ApiResponseCodeEnum.LOCATION_DELETION_CONFLICT:
        errorText = 'error.LOCATION_DELETION_CONFLICT';
        showErrorMessage = false;
        break;
      // Menu Item is Locked
      case ApiResponseCodeEnum.MENU_ITEM_LOCKED:
        errorText = 'error.MENU_ITEM_LOCKED';
        break;
      case ApiResponseCodeEnum.UNASSIGN_EQUIPMENT_LINKED_TO_SENSOR:
      case ApiResponseCodeEnum.UNASSIGN_EQUIPMENT_ASSIGNED_TO_TASKS:
        showErrorMessage = false;
      case ApiResponseCodeEnum.TARGET_LOCATION_HAS_A_STORE_WITH_SAME_NAME:
        errorText = 'error.TARGET_LOCATION_HAS_A_STORE_WITH_SAME_NAME';
        break;
      case ApiResponseCodeEnum.TARGET_LOCATION_HAS_A_STORE_WITH_SAME_NAME_OF_SUBLOCATION:
        errorText = 'error.TARGET_LOCATION_HAS_A_STORE_WITH_SAME_NAME_OF_SUBLOCATION';
        break;
      case ApiResponseCodeEnum.TARGET_LOCATION_IS_THE_SAME_AS_THE_PARENT_LOCATION:
        errorText = 'error.TARGET_LOCATION_IS_THE_SAME_AS_THE_PARENT_LOCATION';
        break;
      case ApiResponseCodeEnum.STORE_HAS_AREAS_UNDER_IT:
        errorText = 'error.STORE_HAS_AREAS_UNDER_IT';
        break;
      case ApiResponseCodeEnum.STORE_HAS_SUB_LOCATIONS_UNDER_IT:
        errorText = 'error.STORE_HAS_SUB_LOCATIONS_UNDER_IT';
        break;

      // Labels Error Codes 600 - 799
      case ApiResponseCodeEnum.LABEL_ADD_ERROR:
        errorText = 'error.LABEL_ADD_ERROR';
        break;
      case ApiResponseCodeEnum.LABEL_NOT_EXISTS:
        errorText = 'error.LABEL_NOT_EXISTS';
        break;
      case ApiResponseCodeEnum.LABEL_TEMPLATE_CODE_ALREADY_EXIST:
        errorText = 'error.LABEL_TEMPLATE_CODE_ALREADY_EXIST';
        break;
      case ApiResponseCodeEnum.LABEL_TEMPLATE_FILE_INCORRECT_FORMAT:
        errorText = 'error.LABEL_TEMPLATE_FILE_INCORRECT_FORMAT';
        break;
      case ApiResponseCodeEnum.LABEL_TEMPLATE_FILE_IS_MISSING:
        errorText = 'error.LABEL_TEMPLATE_FILE_IS_MISSING';
        break;
      case ApiResponseCodeEnum.NO_UPDATE_ALLOWED:
        errorText = 'error.NO_UPDATE_ALLOWED';
        break;
      case ApiResponseCodeEnum.LABEL_DELETION_CONFLICT:
        errorText = 'error.LABEL_DELETION_CONFLICT';
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.LABEL_TEMPLATE_NAME_ALREADY_EXIST:
        errorText = 'error.LABEL_TEMPLATE_NAME_ALREADY_EXIST';
        break;
      case ApiResponseCodeEnum.LABEL_FONT_FILE_NAME_REPEATED:
        errorText = 'error.LABEL_FONT_FILE_NAME_REPEATED';
        break;
      case ApiResponseCodeEnum.LABEL_FONT_NAME_ALREADY_EXISTS:
        errorText = 'error.LABEL_FONT_NAME_ALREADY_EXISTS';
        break;
      case ApiResponseCodeEnum.LABEL_PUBLISH_FAIL:
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.LABEL_FONT_FILE_UPLOAD_FAILED:
        errorText = 'error.LABEL_FONT_FILE_UPLOAD_FAILED';
        break;
      case ApiResponseCodeEnum.LABEL_FONT_DEFAULT_LABEL_ALREADY_ENABLED:
        errorText = 'error.LABEL_FONT_DEFAULT_LABEL_ALREADY_ENABLED';
        break;
      case ApiResponseCodeEnum.LABEL_FONT_DEFAULT_WIDGET_ALREADY_ENABLED:
        errorText = 'error.LABEL_FONT_DEFAULT_WIDGET_ALREADY_ENABLED';
        break;
      case ApiResponseCodeEnum.LABEL_INVALID_OBJECTS:
        errorText = 'error.LABEL_INVALID_OBJECTS';
        duration = 15000;
        break;

      // Brands Error Codes 800 - 999
      case ApiResponseCodeEnum.BRAND_NOT_EXISTS:
        errorText = 'error.BRAND_NOT_EXISTS';
        break;
      case ApiResponseCodeEnum.BRAND_NAME_ALREADY_EXIST:
        errorText = 'error.BRAND_NAME_ALREADY_EXIST';
        break;
      case ApiResponseCodeEnum.BRAND_NAME_SHORTNAME_ALREADY_EXIST:
        errorText = 'error.BRAND_NAME_SHORTNAME_ALREADY_EXIST';
        break;
      case ApiResponseCodeEnum.BRAND_NAME_SHORTNAME_RBrand_ALREADY_EXIST:
        errorText = 'error.BRAND_NAME_SHORTNAME_RBrand_ALREADY_EXIST';
        break;
      case ApiResponseCodeEnum.BRAND_SHORTNAME_ALREADY_EXIST:
        errorText = 'error.BRAND_SHORTNAME_ALREADY_EXIST';
        break;
      case ApiResponseCodeEnum.BRAND_SHORTNAME_RBRAND_ALREADY_EXIST:
        errorText = 'error.BRAND_SHORTNAME_RBRAND_ALREADY_EXIST';
        break;
      case ApiResponseCodeEnum.BRAND_NAME_RBRAND_ALREADY_EXIST:
        errorText = 'error.BRAND_NAME_RBRAND_ALREADY_EXIST';
        break;
      case ApiResponseCodeEnum.BRAND_RBRAND_ALREADY_EXIST:
        errorText = 'error.BRAND_RBRAND_ALREADY_EXIST';
        break;
      case ApiResponseCodeEnum.BRAND_IMAGE_RECOGNITION_LABEL_KEY_ALREADY_EXISTS:
        errorText = 'error.BRAND_IMAGE_RECOGNITION_LABEL_KEY_ALREADY_EXISTS';
        break;
      case ApiResponseCodeEnum.BRAND_IMAGE_RECOGNITION_LABEL_ASSSIGNED_TO_INGREDIENT:
        errorText = 'error.BRAND_IMAGE_RECOGNITION_LABEL_ASSSIGNED_TO_INGREDIENT';
        break;
      case ApiResponseCodeEnum.BRAND_IS_IN_USE:
        errorText = 'error.BRAND_IS_IN_USE';
        break;
      case ApiResponseCodeEnum.BRAND_LOGO_WRONG_DIMENSIONS:
        errorText = 'error.BRAND_LOGO_WRONG_DIMENSIONS';
        showErrorMessage = false;
      case ApiResponseCodeEnum.BRAND_BANNER_WRONG_DIMENSIONS:
        errorText = 'error.BRAND_BANNER_WRONG_DIMENSIONS';
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.BRAND_PREFIX_ALREADY_EXIST:
        errorText = 'error.BRAND_PREFIX_ALREADY_EXIST';
        break;
      case ApiResponseCodeEnum.BRAND_EXTERNAL_ID_ALREADY_EXIST:
        errorText = 'error.BRAND_EXTERNAL_ID_ALREADY_EXIST';
        break;
      case ApiResponseCodeEnum.BRAND_NAME_EXISTS_FOR_RESELLER:
        errorText = 'error.BRAND_NAME_EXISTS_FOR_RESELLER';
        break;

      // Tenants Error Codes 1000 - 1199
      case ApiResponseCodeEnum.TENANT_NOT_EXIST:
        errorText = 'error.TENANT_NOT_EXIST';
        break;
      case ApiResponseCodeEnum.TENANT_IS_NOT_A_RESELLER:
        errorText = 'error.TENANT_IS_NOT_A_RESELLER';
        break;
      case ApiResponseCodeEnum.ACCOUNT_HAS_NO_PERMISSION_TO_CREATE_OR_EDIT_ACCOUNT:
        errorText = 'error.ACCOUNT_HAS_NO_PERMISSION_TO_CREATE_OR_EDIT_ACCOUNT';
        break;
      case ApiResponseCodeEnum.TENANT_NAME_ALREADY_EXIST:
        errorText = 'error.TENANT_NAME_ALREADY_EXIST';
        break;
      case ApiResponseCodeEnum.CUSTOMER_EXTERNAL_ID_ALREADY_EXIST:
        errorText = 'error.CUSTOMER_EXTERNAL_ID_ALREADY_EXIST';
        break;
      case ApiResponseCodeEnum.LOCATION_HAS_CHILDREN:
        errorText = 'error.LOCATION_HAS_CHILDREN';
        break;
      case ApiResponseCodeEnum.LOCATION_IS_A_STORE:
        errorText = 'error.LOCATION_IS_A_STORE';
        break;
      case ApiResponseCodeEnum.TENANT_HAS_SUBLOCATIONS_CREATED:
        errorText = 'error.TENANT_HAS_SUBLOCATIONS_CREATED';
        break;
      case ApiResponseCodeEnum.TENANT_UPDATE_NOT_ALLOWED_TO_DISABLE_LABELING_FLAG:
        errorText = 'error.TENANT_UPDATE_NOT_ALLOWED_TO_DISABLE_LABELING_FLAG';
        break;
      case ApiResponseCodeEnum.TENANT_UPDATE_NOT_ALLOWED_TO_DISABLE_RFID_SUPPORT_FLAG:
        errorText = 'error.TENANT_UPDATE_NOT_ALLOWED_TO_DISABLE_RFID_SUPPORT_FLAG';
        break;
      case ApiResponseCodeEnum.TENANT_UPDATE_NOT_ALLOWED_TO_DISABLE_MEDIA_MANAGER_FLAG:
        errorText = 'error.TENANT_UPDATE_NOT_ALLOWED_TO_DISABLE_MEDIA_MANAGER_FLAG';
        break;
      // Has no access to import devices Code 213
      case ApiResponseCodeEnum.HAS_IMPORT_TERMINAL_ACCESS:
        errorText = 'error.HAS_IMPORT_TERMINAL_ACCESS';
        break;

      // Updating account mode 1004 1005
      case ApiResponseCodeEnum.TENANT_MODE_ORDER_NOT_RESPECTED:
        errorText = 'error.TENANT_MODE_ORDER_NOT_RESPECTED';
        break;
      case ApiResponseCodeEnum.COULD_NOT_UPDATE_THE_MODE_FOR_NON_TRANSACT_USERS:
        errorText = 'error.COULD_NOT_UPDATE_THE_MODE_FOR_NON_TRANSACT_USERS';
        interpolateParams = { reseller: this.authService.getResellerName() };
        break;

      // Enabling recipe management
      case ApiResponseCodeEnum.TENANT_NOT_ALLOWED_TO_HAVE_RECIPE_MANAGEMENT_ENABLED:
        errorText = 'error.TENANT_NOT_ALLOWED_TO_HAVE_RECIPE_MANAGEMENT_ENABLED';
        showErrorMessage = false;
        break;

      // Account validation Response is not valid
      case ApiResponseCodeEnum.ACCOUNT_INFORMATION_IS_NOT_VALID:
        errorText = 'error.ACCOUNT_INFORMATION_IS_NOT_VALID';
        break;

      // Users Error Codes 1200 - 1399
      case ApiResponseCodeEnum.LOGIN_INVALID_CREDENTIALS:
        errorText = 'error.LOGIN_INVALID_CREDENTIALS';
        break;
      case ApiResponseCodeEnum.USER_EMAIL_ALREADY_EXIST:
        errorText = 'error.USER_EMAIL_ALREADY_EXIST';
        break;
      case ApiResponseCodeEnum.USER_PIN_ALREADY_EXIST:
        errorText = 'error.USER_PIN_ALREADY_EXIST';
        break;
      case ApiResponseCodeEnum.USER_EMPLOYEE_ID_ALREADY_EXIST:
        errorText = 'error.USER_EMPLOYEE_ID_ALREADY_EXIST';
        break;
      case ApiResponseCodeEnum.USER_NAME_OR_ID_INVALID:
        errorText = 'error.USER_NAME_OR_ID_INVALID';
        break;
      case ApiResponseCodeEnum.GUID_INVALID:
        errorText = 'error.GUID_INVALID';
        break;
      case ApiResponseCodeEnum.USER_NOT_ASSIGNED_TO_TERMINAL_LOCATION:
        errorText = 'error.USER_NOT_ASSIGNED_TO_TERMINAL_LOCATION';
        break;
      case ApiResponseCodeEnum.SYSTEM_ADMIN_ROLE_DOES_NOT_EXIST:
        errorText = 'error.USER_NOT_ASSIGNED_TO_TERMINAL_LOCATION';
        break;
      case ApiResponseCodeEnum.USER_BLOCKED_CAN_NOT_LOGIN:
        showErrorMessage = false;
        break;

      case ApiResponseCodeEnum.MOVE_LOCATION_NOT_CONFIRMED:
      case ApiResponseCodeEnum.MOVE_LOCATION_VALIDATION_CHECKLIST_CONFLICTS:
      case ApiResponseCodeEnum.MOVE_LOCATION_VALIDATION_CONFLICTS:
        showErrorMessage = false;
        break;

      case ApiResponseCodeEnum.EMAIL_REQUIRED:
        errorText = 'error.EMAIL_REQUIRED';
        break;
      case ApiResponseCodeEnum.INVALID_USER_PIN_LENGTH:
        errorText = 'error.INVALID_USER_PIN_LENGTH';
        break;
      case ApiResponseCodeEnum.ASSIGN_NON_RESELLER_ROLE_TO_RESELLER_USER:
        errorText = 'error.ASSIGN_NON_RESELLER_ROLE_TO_RESELLER_USER';
        break;
      case ApiResponseCodeEnum.USER_DOES_NOT_EXIST:
        errorText = 'error.USER_DOES_NOT_EXIST';
        break;
      case ApiResponseCodeEnum.USER_ALREADY_ACTIVATED:
        errorText = 'error.USER_ALREADY_ACTIVATED';
        break;
      case ApiResponseCodeEnum.USER_PASSWORD_SET:
        errorText = 'error.USER_PASSWORD_SET';
        break;
      case ApiResponseCodeEnum.USER_INACTIVE:
        errorText = 'error.USER_INACTIVE';
        break;
      case ApiResponseCodeEnum.USER_ACTIVATION_FAILED:
        errorText = 'error.USER_ACTIVATION_FAILED';
        break;
      case ApiResponseCodeEnum.USER_IMPORT_LOCATION_CODE_ERROR:
        errorText = 'error.USER_IMPORT_LOCATION_CODE_ERROR';
        break;
      case ApiResponseCodeEnum.USER_MANAGE_LOCATION_INVALID_USER_ID:
        errorText = 'error.USER_MANAGE_LOCATION_INVALID_USER_ID';
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.USER_MANAGE_LOCATION_INVALID_LOCATION_CODE:
        errorText = 'error.USER_MANAGE_LOCATION_INVALID_LOCATION_CODE';
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.USER_CHANGE_PASSWORD_LINK_EXPIRED:
        errorText = 'error.USER_CHANGE_PASSWORD_LINK_EXPIRED';
        break;

      case ApiResponseCodeEnum.USER_PIN_IS_INVALID:
        errorText = 'error.USER_PIN_IS_INVALID';
        break;
      // Menus Error Codes 1400 - 1599
      case ApiResponseCodeEnum.MENU_ASSIGNED_TO_LOCATION:
        errorText = 'error.MENU_ASSIGNED_TO_LOCATION';
        break;
      case ApiResponseCodeEnum.MENU_NOT_EXISTS:
        errorText = 'error.MENU_NOT_EXISTS';
        this.authService.redirectToPageNotFound();
        break;
      case ApiResponseCodeEnum.MENU_NOT_ASSIGNED_TO_LOCATION:
        errorText = 'error.MENU_NOT_ASSIGNED_TO_LOCATION';
        break;
      case ApiResponseCodeEnum.MENU_INVALID_SUBSCRIPTION_CODE:
        errorText = 'error.MENU_INVALID_SUBSCRIPTION_CODE';
        break;
      case ApiResponseCodeEnum.TENANT_OWNS_MENU:
        errorText = 'error.TENANT_OWNS_MENU';
        break;
      case ApiResponseCodeEnum.TENANT_MENU_ALREADY_SUBSCRIBED:
        errorText = 'error.TENANT_MENU_ALREADY_SUBSCRIBED';
        break;
      case ApiResponseCodeEnum.TENANT_MENU_WRONG_BRAND:
        errorText = 'error.TENANT_MENU_WRONG_BRAND';
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.TENANT_IS_NOT_A_PUBLISHER_ON_THIS_BRAND:
        errorText = 'error.TENANT_IS_NOT_A_PUBLISHER_ON_THIS_BRAND';
        break;
      case ApiResponseCodeEnum.MENU_ASSIGNED_TO_NODE:
        errorText = 'error.MENU_ASSIGNED_TO_NODE';
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.MENU_HAS_SUBSCRIPTIONS:
        errorText = 'error.MENU_HAS_SUBSCRIPTIONS';
        break;
      case ApiResponseCodeEnum.MENU_IS_SUBSCRIPTION:
        errorText = 'error.MENU_IS_SUBSCRIPTION';
        break;
      case ApiResponseCodeEnum.MENU_HAS_SUBSCRIBERS:
        errorText = 'error.MENU_HAS_SUBSCRIBERS';
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.MENU_IS_CUSTOMIZED:
        errorText = 'error.MENU_IS_CUSTOMIZED';
        break;
      case ApiResponseCodeEnum.MENU_HAS_SUBSCRIPTION_CODE:
        errorText = 'error.MENU_HAS_SUBSCRIPTION_CODE';
        break;
      case ApiResponseCodeEnum.MENU_NAME_ALREADY_EXISTS:
        errorText = 'error.MENU_NAME_ALREADY_EXISTS';
        break;
      case ApiResponseCodeEnum.SUBSCRIBER_DOES_NOT_HAVE_RECIPE_FLAG_ENABLED:
        errorText = 'error.SUBSCRIBER_DOES_NOT_HAVE_RECIPE_FLAG_ENABLED';
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.PUBLISHER_DOES_NOT_HAVE_RECIPE_FLAG_ENABLED:
        errorText = 'error.PUBLISHER_DOES_NOT_HAVE_RECIPE_FLAG_ENABLED';
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.MENU_HAS_CUSTUMIZED_ITEMS:
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.MENU_CANNOT_BE_APPROVED_PENDING_RECIPE_MANAGEMENT_NOT_ENABLED:
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.MENU_ALREADY_PUBLISHED_AT_LOCATION:
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.MENU_ITEM_ALREADY_PUBLISHED_AT_LOCATION:
      case ApiResponseCodeEnum.MENU_BULK_EDIT_OVERRIDE_ITEM_STYLE:
        showErrorMessage = false;
        break;

      // MenuItems, Products, Ingredients and Categories Error Codes 1600 - 1799
      case ApiResponseCodeEnum.INGREDIENT_INVALID:
        errorText = 'error.INGREDIENT_INVALID';
        break;
      case ApiResponseCodeEnum.INGREDIENT_NOT_EXISTS:
        errorText = 'error.INGREDIENT_NOT_EXISTS';
        break;
      case ApiResponseCodeEnum.INGREDIENT_ASSIGNED_TO_MENU:
        errorText = 'error.INGREDIENT_ASSIGNED_TO_MENU';
        break;
      case ApiResponseCodeEnum.INGREDIENT_SHELF_LIFE_MANDATORY:
        errorText = 'error.INGREDIENT_SHELF_LIFE_MANDATORY';
        break;
      case ApiResponseCodeEnum.INGREDIENT_UOM_ASSIGNED_TO_RECIPE:
        errorText = 'error.INGREDIENT_UOM_ASSIGNED_TO_RECIPE';
        break;
      case ApiResponseCodeEnum.INGREDIENT_CANNOT_HAVE_UOM_OTHER_THAN_GRAM:
        errorText = 'error.INGREDIENT_CANNOT_HAVE_UOM_OTHER_THAN_GRAM';
        break;
      case ApiResponseCodeEnum.UOM_WRONG_FOR_ASSIGNED_COMPONENTS:
        errorText = 'error.UOM_WRONG_FOR_ASSIGNED_COMPONENTS';
        break;
      case ApiResponseCodeEnum.RECIPE_CANNOT_HAVE_UOM_OTHER_THAN_GRAM:
        errorText = 'error.RECIPE_CANNOT_HAVE_UOM_OTHER_THAN_GRAM';
        break;
      case ApiResponseCodeEnum.PRODUCT_ASSIGNED_TO_MENU:
        errorText = 'error.PRODUCT_ASSIGNED_TO_MENU';
        break;
      case ApiResponseCodeEnum.PRODUCT_NOT_EXISTS:
        errorText = 'error.PRODUCT_NOT_EXISTS';
        break;
      case ApiResponseCodeEnum.THUMBNAIL_IMAGE_FILE_IS_MISSING:
        errorText = 'error.THUMBNAIL_IMAGE_FILE_IS_MISSING';
        break;
      case ApiResponseCodeEnum.THUMBNAIL_IMAGE_FILE_LARGER_THAN_500_KB:
        errorText = 'error.THUMBNAIL_IMAGE_FILE_LARGER_THAN_500_KB';
        break;
      case ApiResponseCodeEnum.LABEL_MANDATORY:
        errorText = 'error.LABEL_MANDATORY';
        break;
      case ApiResponseCodeEnum.MENUITEM_DELETION_CONFLICT:
        errorText = 'error.MENUITEM_DELETION_CONFLICT';
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.MENUITEM_NOT_IN_USE:
        errorText = 'error.MENUITEM_NOT_IN_USE';
        break;
      case ApiResponseCodeEnum.MENUITEM_PUBLISH_CONFLICT:
        errorText = 'error.MENUITEM_PUBLISH_CONFLICT';
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.PRODUCT_NAME_ALREADY_EXISTS:
        errorText = 'error.PRODUCT_NAME_ALREADY_EXISTS';
        break;
      case ApiResponseCodeEnum.INGREDIENT_NAME_ALREADY_EXISTS:
        errorText = 'error.INGREDIENT_NAME_ALREADY_EXISTS';
        break;
      case ApiResponseCodeEnum.CATEGORY_CANNOT_BE_DELETED_AT_THIS_LOCATION:
        errorText = 'error.CATEGORY_CANNOT_BE_DELETED_AT_THIS_LOCATION';
        break;
      case ApiResponseCodeEnum.MENUITEM_CATEGORY_NAME_ALREADY_EXISTS:
        errorText = 'error.MENUITEM_CATEGORY_NAME_ALREADY_EXISTS';
        break;

      // Recipes Error Codes 1800 - 1999
      case ApiResponseCodeEnum.RECIPE_INVALID:
        errorText = 'error.RECIPE_INVALID';
        break;
      case ApiResponseCodeEnum.PRODUCT_COULD_NOT_UNASSIGN_RECIPES:
        errorText = 'error.PRODUCT_COULD_NOT_UNASSIGN_RECIPES';
        break;
      case ApiResponseCodeEnum.ASSIGNED_LABELS_MUST_BE_DIFFERENT:
        errorText = 'error.ASSIGNED_LABELS_MUST_BE_DIFFERENT';
        break;
      case ApiResponseCodeEnum.RECIPE_DELETION_CONFLICT:
        errorText = 'error.RECIPE_DELETION_CONFLICT';
        showErrorMessage = false;
        break;

      case ApiResponseCodeEnum.RECIPE_NAME_ALREADY_EXISTS:
        errorText = 'error.RECIPE_NAME_ALREADY_EXISTS';
        break;
      case ApiResponseCodeEnum.RECIPE_UOM_ASSIGNED_TO_RECIPE:
        errorText = 'error.RECIPE_UOM_ASSIGNED_TO_RECIPE';
        break;
      case ApiResponseCodeEnum.RECIPE_UOM_ASSIGNED_TO_PRODUCT:
        errorText = 'error.RECIPE_UOM_ASSIGNED_TO_PRODUCT';
        break;

      case ApiResponseCodeEnum.PRODUCT_SHOULD_HAVE_ONE_PRICE_OPTION:
        errorText = 'error.PRODUCT_SHOULD_HAVE_ONE_PRICE_OPTION';
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.DELETE_SHORTCUTS_NOT_CONFIRMED:
        showErrorMessage = false;
        break;
      // Nutrition facts Error Codes 2000 - 2199
      case ApiResponseCodeEnum.NF_TRANSLATION_ADD_ERROR:
        errorText = 'error.NF_TRANSLATION_ADD_ERROR';
        break;
      case ApiResponseCodeEnum.NF_TRANSLATION_UPDATE_ERROR:
        errorText = 'error.NF_TRANSLATION_UPDATE_ERROR';
        break;
      case ApiResponseCodeEnum.NF_UPDATE_ERROR:
        errorText = 'error.NF_UPDATE_ERROR';
        break;

      // Settings error codes 2200 - 2399
      case ApiResponseCodeEnum.STYLE_NAME_ALREADY_EXISTS:
        errorText = 'error.STYLE_NAME_ALREADY_EXISTS';
        break;

      case ApiResponseCodeEnum.CUSTOM_FIELD_NAME_ALREADY_EXISTS:
        errorText = 'error.CUSTOM_FIELD_NAME_ALREADY_EXISTS';
        break;

      case ApiResponseCodeEnum.CUSTOM_FIELD_ALREADY_ASSIGNED_CANNOT_DELETE:
        errorText = 'error.CUSTOM_FIELD_ALREADY_ASSIGNED_CANNOT_DELETE';
        break;

      case ApiResponseCodeEnum.CUSTOM_FIELLD_ALREADY_ASSIGNED_CANNOT_UPDATE_TYPE:
        errorText = 'error.CUSTOM_FIELLD_ALREADY_ASSIGNED_CANNOT_UPDATE_TYPE';
        break;

      case ApiResponseCodeEnum.TEMP_SESSION_NAME_ALREADY_EXISTS:
        errorText = 'error.TEMP_SESSION_NAME_ALREADY_EXISTS';
        break;
      case ApiResponseCodeEnum.TEMP_SESSION_OVERNIGHT:
        errorText = 'error.TEMP_SESSION_OVERNIGHT';
        break;
      case ApiResponseCodeEnum.TEMP_SESSION_ADD_IS_SUBSCRIBER:
        errorText = 'error.TEMP_SESSION_ADD_IS_SUBSCRIBER';
        break;
      case ApiResponseCodeEnum.TEMP_SESSION_EDIT_IS_SUBSCRIPTION:
        errorText = 'error.TEMP_SESSION_EDIT_IS_SUBSCRIPTION';
        break;
      case ApiResponseCodeEnum.DATA_TRANSLATION_INVALID_LENGTH:
        errorText = 'error.DATA_TRANSLATION_INVALID_LENGTH';
        break;

      // Media Error
      case ApiResponseCodeEnum.MEDIA_NAME_ALREADY_EXISTS:
        errorText = 'error.MEDIA_NAME_ALREADY_EXISTS';
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.MEDIA_INVALID_FILE_SIZE:
        errorText = 'error.MEDIA_INVALID_FILE_SIZE';
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.MEDIA_DELETION_CONFLICT:
        errorText = 'error.MEDIA_DELETION_CONFLICT';
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.MEDIA_BULK_INVALID_COUNT_SIZE:
        errorText = 'error.MEDIA_BULK_INVALID_COUNT_SIZE';
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.MEDIA_CATEGORY_NAME_ALREADY_EXIST:
        errorText = 'error.MEDIA_CATEGORY_NAME_ALREADY_EXIST';
        break;
      case ApiResponseCodeEnum.MEDIA_CATEGORY_NOT_OF_TYPE_MEDIA_CATEGORY_GENERATE:
        errorText = 'error.MEDIA_CATEGORY_NOT_OF_TYPE_MEDIA_CATEGORY_GENERATE';
        break;
      case ApiResponseCodeEnum.MEDIA_CATEGORY_INVALID_SUBSCRIPTION_CODE:
        errorText = 'error.MEDIA_CATEGORY_INVALID_SUBSCRIPTION_CODE';
        break;
      case ApiResponseCodeEnum.TENANT_MEDIA_CATEGORY_WRONG_BRAND:
        errorText = 'error.TENANT_MEDIA_CATEGORY_WRONG_BRAND';
        break;
      case ApiResponseCodeEnum.TENANT_MEDIA_CATEGORY_ALREADY_SUBSCRIBED:
        errorText = 'error.TENANT_MEDIA_CATEGORY_ALREADY_SUBSCRIBED';
        break;
      case ApiResponseCodeEnum.MEDIA_CATEGORY_CANT_CLEAR_SUBSCRIPTION_CODE_HAS_MEDIA_CATEGORY_SUBSCRIPTION_UNDER_IT:
        errorText = 'error.MEDIA_CATEGORY_CANT_CLEAR_SUBSCRIPTION_CODE_HAS_MEDIA_CATEGORY_SUBSCRIPTION_UNDER_IT';
        break;
      case ApiResponseCodeEnum.MEDIA_CATEGORY_NOT_OF_TYPE_MEDIA_CATEGORY_CLEAR:
        errorText = 'error.MEDIA_CATEGORY_NOT_OF_TYPE_MEDIA_CATEGORY_CLEAR';
        break;
      case ApiResponseCodeEnum.TENANT_OWNS_MEDIA_CATEGORY:
        errorText = 'error.TENANT_OWNS_MEDIA_CATEGORY';
        break;
      case ApiResponseCodeEnum.MEDIA_CATEGORY_HAS_MEDIA:
        errorText = 'error.MEDIA_CATEGORY_HAS_MEDIA';
        break;
      case ApiResponseCodeEnum.MEDIA_CATEGORY_HAS_A_SUBSCRIPTION_CODE:
        errorText = 'error.MEDIA_CATEGORY_HAS_A_SUBSCRIPTION_CODE';
        break;
      case ApiResponseCodeEnum.MEDIA_DIFFERENT_TENANT_CANNOT_DELETE:
        errorText = 'error.MEDIA_DIFFERENT_TENANT_CANNOT_DELETE';
        break;
      case ApiResponseCodeEnum.MEDIA_CATEGORY_NOT_OF_TYPE_MEDIA_CATEGORY_ADD:
        errorText = 'error.MEDIA_CATEGORY_NOT_OF_TYPE_MEDIA_CATEGORY_ADD';
        break;
      case ApiResponseCodeEnum.MEDIA_CATEGORY_HAS_MEDIA_SUBSCRIPTIONS_UNDER_IT_GENERATE:
        errorText = 'error.MEDIA_CATEGORY_HAS_MEDIA_SUBSCRIPTIONS_UNDER_IT_GENERATE';
        break;
      case ApiResponseCodeEnum.TENANT_ALREADY_SUBSCRIBED_TO_PART_OF_MEDIA_CATEGORY:
        errorText = 'error.TENANT_ALREADY_SUBSCRIBED_TO_PART_OF_MEDIA_CATEGORY';
        break;
      case ApiResponseCodeEnum.MEDIA_CATEGORY_HAS_NO_MEDIAS:
        errorText = 'error.MEDIA_CATEGORY_HAS_NO_MEDIAS';
        break;
      case ApiResponseCodeEnum.MEDIA_CANNOT_BE_ADDED_UNDER_ROOT_MEDIA_CATEGORY:
        errorText = 'error.MEDIA_CANNOT_BE_ADDED_UNDER_ROOT_MEDIA_CATEGORY';
        break;
      case ApiResponseCodeEnum.MEDIA_CATEGORY_PARENT_HAS_SUBSCRIPTION_CODE:
        errorText = 'error.MEDIA_CATEGORY_PARENT_HAS_SUBSCRIPTION_CODE';
        break;

      case ApiResponseCodeEnum.MEDIA_CATEGORY_HAS_ACTIVE_MEDIA_DELETE:
        errorText = 'error.MEDIA_CATEGORY_HAS_ACTIVE_MEDIA_DELETE';
        break;
      case ApiResponseCodeEnum.MEDIA_CATEGORY_HAS_MEDIA_SUBSCRIPTIONS_DELETE:
        errorText = 'error.MEDIA_CATEGORY_HAS_MEDIA_SUBSCRIPTIONS_DELETE';
        break;

      // Settings error codes 2600
      case ApiResponseCodeEnum.ADDON_INVALID_TYPE:
        errorText = 'error.ADDON_INVALID_TYPE';
        break;
      case ApiResponseCodeEnum.ADDON_NAME_ALREADY_EXISTS:
        errorText = 'error.ADDON_NAME_ALREADY_EXISTS';
        break;
      case ApiResponseCodeEnum.ADDON_PRICE_CANNOT_BE_NEGATIVE:
        errorText = 'error.ADDON_PRICE_CANNOT_BE_NEGATIVE';
        break;
      case ApiResponseCodeEnum.ADDON_DELETION_CONFLICT:
        errorText = 'error.ADDON_DELETION_CONFLICT';
        showErrorMessage = false;
        break;

      //Users Import Error codes
      case ApiResponseCodeEnum.FILE_TYPE_NOT_SUPPORTED:
        errorText = 'error.FILE_TYPE_NOT_SUPPORTED';
        break;
      case ApiResponseCodeEnum.FILE_IMPORT_BAD_DATA:
        errorText = 'error.FILE_IMPORT_BAD_DATA';
        break;
      case ApiResponseCodeEnum.FILE_IMPORT_EMPTY:
        errorText = 'error.FILE_IMPORT_EMPTY';
        break;
      case ApiResponseCodeEnum.FILE_WRONG_FORMAT:
        errorText = 'error.FILE_WRONG_FORMAT';
        break;
      case ApiResponseCodeEnum.FILE_IMPORT_DUPLICATE_VALUES:
        errorText = 'error.FILE_IMPORT_DUPLICATE_VALUES';
        break;
      case ApiResponseCodeEnum.FILE_IMPORT_DUPLICATE_USERS_IDS:
        errorText = 'error.FILE_IMPORT_DUPLICATE_USERS_IDS';
        break;
      case ApiResponseCodeEnum.USER_IMPORT_ALREADY_EXISTS_IN_DB:
        errorText = 'error.USER_IMPORT_ALREADY_EXISTS_IN_DB';
        break;
      case ApiResponseCodeEnum.USER_DUPLICATE_KEY_IN_FILE:
        errorText = 'error.USER_DUPLICATE_KEY_IN_FILE';
        break;
      case ApiResponseCodeEnum.USER_LIST_CANNOT_BE_EMPTY:
        errorText = 'error.USER_LIST_CANNOT_BE_EMPTY';
        break;
      case ApiResponseCodeEnum.BAD_REQUEST:
        errorText = 'error.BAD_REQUEST';
        break;
      case ApiResponseCodeEnum.TERMINAL_IMPORT_ALREADY_EXISTS_IN_DB:
        errorText = 'error.TERMINAL_IMPORT_ALREADY_EXISTS_IN_DB';
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.TERMINAL_DUPLICATE_KEY_IN_FILE:
        errorText = 'error.TERMINAL_DUPLICATE_KEY_IN_FILE';
        break;
      case ApiResponseCodeEnum.USER_COUNTRY_ISO_CODE_INVALID_LENGTH:
        errorText = 'error.USER_COUNTRY_ISO_CODE_INVALID_LENGTH';
        break;

      // Import locations Error Code
      case ApiResponseCodeEnum.FILE_IMPORT_DUPLICATE_IDS:
        errorText = 'error.FILE_IMPORT_DUPLICATE_IDS';
        break;
      case ApiResponseCodeEnum.FILE_IMPORT_LOCATION_PARENTID_ROOT_NODE_DOES_NOT_EXIST:
        errorText = 'error.FILE_IMPORT_LOCATION_PARENTID_ROOT_NODE_DOES_NOT_EXIST';
        break;
      case ApiResponseCodeEnum.FILE_IMPORT_STORE_ADDRESS_AND_PHONE_NUMBER_ARE_MANDATORY:
        errorText = 'error.FILE_IMPORT_STORE_ADDRESS_AND_PHONE_NUMBER_ARE_MANDATORY';
        break;
      case ApiResponseCodeEnum.FILE_IMPORT_STORE_COUNTRY_AND_CODE_AND_PHONE_NUMBER_CODE_ARE_MANDATORY:
        errorText = 'error.FILE_IMPORT_STORE_COUNTRY_AND_CODE_AND_PHONE_NUMBER_CODE_ARE_MANDATORY';
        break;
      case ApiResponseCodeEnum.FILE_IMPORT_STORE_COUNTRY_FIELDS_MUST_BE_IN_CORRECT_FORMAT:
        errorText = 'error.FILE_IMPORT_STORE_COUNTRY_FIELDS_MUST_BE_IN_CORRECT_FORMAT';
        break;
      case ApiResponseCodeEnum.FILE_IMPORT_PARENT_ID_NOT_DEFINED_IN_IDS:
        errorText = 'error.FILE_IMPORT_PARENT_ID_NOT_DEFINED_IN_IDS';
        break;
      case ApiResponseCodeEnum.FILE_IMPORT_LOCATION_NAME_ALREADY_EXISTS_UNDER_SAME_LOCATION:
        errorText = 'error.FILE_IMPORT_LOCATION_NAME_ALREADY_EXISTS_UNDER_SAME_LOCATION';
        break;
      case ApiResponseCodeEnum.FILE_IMPORT_LOCATION_NAME_MANDATORY:
        errorText = 'error.FILE_IMPORT_LOCATION_NAME_MANDATORY';
        break;
      case ApiResponseCodeEnum.FILE_IMPORT_LOCATION_ID_MANDATORY:
        errorText = 'error.FILE_IMPORT_LOCATION_ID_MANDATORY';
        break;
      case ApiResponseCodeEnum.FILE_IMPORT_LOCATION_HIERARCHY_PROBLEM:
        errorText = 'error.FILE_IMPORT_LOCATION_HIERARCHY_PROBLEM';
        break;
      case ApiResponseCodeEnum.FILE_IMPORT_LOCATION_ONLY_ONE_STORE_ALLOWED_PER_BRANCH:
        errorText = 'error.FILE_IMPORT_LOCATION_ONLY_ONE_STORE_ALLOWED_PER_BRANCH';
        break;
      case ApiResponseCodeEnum.FILE_IMPORT_LOCATION_STORE_NUMBER_ALREADY_EXISTS:
        errorText = 'error.FILE_IMPORT_LOCATION_STORE_NUMBER_ALREADY_EXISTS';
        break;
      case ApiResponseCodeEnum.FILE_IMPORT_LOCATION_ID_DOENT_EXIST:
        errorText = 'error.FILE_IMPORT_LOCATION_ID_DOENT_EXIST';
        break;
      case ApiResponseCodeEnum.FILE_IMPORT_LOCATION_DUPLICATE_STORE_NUMBER_IN_FILE:
        errorText = 'error.FILE_IMPORT_LOCATION_DUPLICATE_STORE_NUMBER_IN_FILE';
        break;
      case ApiResponseCodeEnum.FILE_IMPORT_STORE_COUNTRY_ISO_CODE_INVALID_LENGTH:
        errorText = 'error.FILE_IMPORT_STORE_COUNTRY_ISO_CODE_INVALID_LENGTH';
        break;

      // Widget Error
      case ApiResponseCodeEnum.WIDGET_DELETION_CONFLICT:
        errorText = 'error.WIDGET_DELETION_CONFLICT';
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.WIDGET_CLONING_FAILED:
        errorText = 'error.WIDGET_CLONING_FAILED';
        break;
      case ApiResponseCodeEnum.WIDGET_NAME_ALREADY_EXISTS:
        errorText = 'error.WIDGET_NAME_ALREADY_EXISTS';
        break;
      case ApiResponseCodeEnum.WIDGET_NFPROFILE_MISMATCH:
        errorText = 'error.WIDGET_NFPROFILE_MISMATCH';
        break;
      case ApiResponseCodeEnum.WIDGET_NFPROFILE_MISMATCH_FOR_WIDGET:
        errorText = 'error.WIDGET_NFPROFILE_MISMATCH_FOR_WIDGET';
        break;
      case ApiResponseCodeEnum.WIDGET_USED_BY_SUBSCRIBERS:
        showErrorMessage = false;
        break;

        break;
      // Email error
      case ApiResponseCodeEnum.ACTIVATION_LINK_ALREADY_SENT:
        errorText = 'error.ACTIVATION_LINK_ALREADY_SENT';
        break;
      case ApiResponseCodeEnum.SMTP_ERROR:
        errorText = 'error.SMTP_ERROR';
        break;
      case ApiResponseCodeEnum.INVALID_LINK_TOKEN:
        errorText = 'error.INVALID_LINK_TOKEN';
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.LINK_TOKEN_EXPIRED:
        errorText = 'error.LINK_TOKEN_EXPIRED';
        showErrorMessage = false;
        break;
      // roles
      case ApiResponseCodeEnum.ROLE_NOT_FOUND:
        errorText = 'error.ROLE_NOT_FOUND';
        break;
      case ApiResponseCodeEnum.MODULES_NOT_FOUND:
        errorText = 'error.MODULES_NOT_FOUND';
        break;
      case ApiResponseCodeEnum.MODULE_PERMISSION_NOT_FOUND:
        errorText = 'error.MODULE_PERMISSION_NOT_FOUND';
        break;
      case ApiResponseCodeEnum.DUPLICATE_ROLE_NAME:
        errorText = 'error.DUPLICATE_ROLE_NAME';
        break;
      // DIS
      case ApiResponseCodeEnum.DIS_ON_DEMAND_IMPORT_ALREADY_REQUESTED:
        errorText = 'error.DIS_ON_DEMAND_IMPORT_ALREADY_REQUESTED';
        break;
      // LOGS
      case ApiResponseCodeEnum.LOG_DIRECTORY_NOT_FOUND:
        errorText = 'error.LOG_DIRECTORY_NOT_FOUND';
        break;
      case ApiResponseCodeEnum.LOG_FILE_NOT_FOUND:
        errorText = 'error.LOG_FILE_NOT_FOUND';
        break;
      case ApiResponseCodeEnum.LABEL_SIZE_DELETION_CONFLICT:
        errorText = 'error.LABEL_SIZE_DELETION_CONFLICT';
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.LABEL_SIZE_USED_BY_SUBSCRIBERS:
        showErrorMessage = false;
        break;

      // AUDIT TRAIL
      case ApiResponseCodeEnum.AUDIT_TRAIL_LIMIT_EXCEEDED:
        errorText = 'error.AUDIT_TRAIL_LIMIT_EXCEEDED';
        break;
      case ApiResponseCodeEnum.NO_REPORT_AVAILABLE_FOR_THIS_DATE:
        errorText = 'error.NO_REPORT_AVAILABLE_FOR_THIS_DATE';
        break;
      case ApiResponseCodeEnum.WORKFLOW_TEMPLATE_LINKED_TO_ACCOUNT:
        errorText = 'error.WORKFLOW_TEMPLATE_LINKED_TO_ACCOUNT_ERROR';
        break;
      case ApiResponseCodeEnum.WORKFLOW_USED_IN_TASKS:
        errorText = 'error.WORKFLOW_LINKED_TO_TASK_ERROR';
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.WORKFLOW_NAME_ALREADY_EXISTS:
        errorText = 'error.WORKFLOW_NAME_ALREADY_EXISTS';
        break;
      case ApiResponseCodeEnum.WORKFLOW__IMAGE_FILE_IS_MISSING:
        errorText = 'error.WORKFLOW__IMAGE_FILE_IS_MISSING';
        break;
      case ApiResponseCodeEnum.WORKFLOW_TEMPLATE_NAME_ALREADY_EXISTS:
        errorText = 'error.WORKFLOW_TEMPLATE_NAME_ALREADY_EXISTS';
        break;
      case ApiResponseCodeEnum.WORKFLOW_Workflow_NOT_EXIST:
        errorText = 'error.WORKFLOW_Workflow_NOT_EXIST';
        break;
      case ApiResponseCodeEnum.WORKFLOW_TENANT_NAME_ALREADY_EXISTS:
        errorText = 'error.WORKFLOW_TENANT_NAME_ALREADY_EXISTS';
        break;
      case ApiResponseCodeEnum.WORKFLOW_TEMPLATE_KEY_ALREADY_EXISTS:
        errorText = 'error.WORKFLOW_TEMPLATE_KEY_ALREADY_EXISTS';
        break;
      case ApiResponseCodeEnum.TASK_NAME_ALREADY_EXISTS:
        errorText = 'error.TASK_NAME_ALREADY_EXISTS';
        break;
      case ApiResponseCodeEnum.CHECKLIST_CANT_BE_PUBLISHED:
        errorText = 'error.CHECKLIST_CANT_BE_PUBLISHED';
        break;
      case ApiResponseCodeEnum.CHECKLIST_ALREADY_PUBLISHED_AT_LOCATION:
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.OPS_CHECKLIST_CAN_NOT_BE_ADDED_INVALID_TYPE:
        errorText = 'error.OPS_CHECKLIST_CAN_NOT_BE_ADDED_INVALID_TYPE';
        break;
      case ApiResponseCodeEnum.APPROVED_EQUIPMENT_OR_CHECKLIST_SUBSCRIPTION_CAN_NOT_BE_REJECTED:
        errorText = 'error.APPROVED_EQUIPMENT_OR_CHECKLIST_SUBSCRIPTION_CAN_NOT_BE_REJECTED';
        break;
      case ApiResponseCodeEnum.CHECKLIST_SECTION_NAME_ALREADY_EXISTS:
        errorText = 'error.CHECKLIST_SECTION_NAME_ALREADY_EXISTS';
        break;
      case ApiResponseCodeEnum.CHECKLIST_SUBSCRIPTION_CODE_GENERATION_FAILED_NO_ENABLED_TASKS:
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.WORKFLOW_ASSIGNED_TO_INGREDIENT:
        errorText = 'error.WORKFLOW_ASSIGNED_TO_INGREDIENT';
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.CHECKLIST_ASSIGNED_DELETE_CONFLICT:
        errorText = 'error.CHECKLIST_DELETE_CONFLICT';
        break;

      case ApiResponseCodeEnum.CHECKLIST_CANT_SUBSCRIBE_MULTIPLE_PUBLISHERS:
        if (this.publisherAccount) {
          errorText = 'error.CHECKLIST_CANT_APPROVE_MULTIPLE_PUBLISHERS';
        } else {
          errorText = 'error.CHECKLIST_CANT_SUBSCRIBE_MULTIPLE_PUBLISHERS';
        }
        break;

      case ApiResponseCodeEnum.CHECKLIST_CANT_SUBSCRIBE_EXISTING_SESSIONS:
        if (this.publisherAccount) {
          errorText = 'error.CHECKLIST_CANT_APPROVE_EXISTING_SESSIONS';
        } else {
          errorText = 'error.CHECKLIST_CANT_SUBSCRIBE_EXISTING_SESSIONS';
        }
        showErrorMessage = false;
        break;

      //Equipment types subscription Error Messages
      case ApiResponseCodeEnum.EQUIPMENTTYPES_SUBSCRIPTION_CODE_INVALID:
        errorText = 'Subscription code is invalid.';
        break;

      case ApiResponseCodeEnum.EQUIPMENTTYPES_SUBSCRIPTION_CODE_NOT_FOUND:
        errorText = 'Subscription code is invalid.';
        break;

      case ApiResponseCodeEnum.EQUIPMENTTYPES_TENANT_ALREADY_SUBSCRIBED:
        errorText = 'Tenant already subscribed to equipment types.';
        break;

      case ApiResponseCodeEnum.TENANT_EQUIPMENTTYPE_BRAND_ERROR:
        errorText = 'error.TENANT_EQUIPMENTTYPE_WRONG_BRAND';
        break;
      case ApiResponseCodeEnum.EQUIPMENT_TYPE_CAN_NOT_DEACTIVATE_SENSE:
        showErrorMessage = false;
        break;

      //Checklist Options Error Messages
      case ApiResponseCodeEnum.Checklist_Option_Start_End_Time_Should_Be_Unique:
        errorText = 'error.TIME_FRAMES_SHOULD_BE_UNIQUE';
        break;
      case ApiResponseCodeEnum.Checklist_Option_Start_Time_Should_Be_Less_Than_End_Time:
        errorText = 'error.WRONG_FORMAT_START_END_TIME';
        break;
      case ApiResponseCodeEnum.CHECKLIST_OPTION_CHECKLIST_TYPE_IN_USE_CAN_NOT_BE_DELETED:
      case ApiResponseCodeEnum.CHECKLIST_OPTION_TASK_SEVERITY_LEVEL_IN_USE_CAN_NOT_BE_DELETED:
      case ApiResponseCodeEnum.CHECKLIST_OPTION_TASK_DIFFICULTY_LEVEL_IN_USE_CAN_NOT_BE_DELETED:
      case ApiResponseCodeEnum.CHECKLIST_OPTION_TASK_TYPE_IN_USE_CAN_NOT_BE_DELETED:
      case ApiResponseCodeEnum.CHECKLIST_OPTION_LOCATION_AREA_IN_USE_CAN_NOT_BE_DELETED:
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.GROUPTAG_NAME_ALREADY_EXISTS:
        errorText = 'error.GROUPTAG_NAME_ALREADY_EXISTS';
        break;
      case ApiResponseCodeEnum.GROUPTAG_DOES_NOT_EXIST:
        errorText = 'error.GROUPTAG_DOES_NOT_EXIST';
        break;
      case ApiResponseCodeEnum.CHECKLIST_NAME_ALREADY_EXISTS:
        errorText = 'error.CHECKLIST_NAME_ALREADY_EXISTS';
        break;
      case ApiResponseCodeEnum.EQUIPMENT_TYPE_NAME_ALREADY_EXISTS:
        errorText = 'error.EQUIPMENT_TYPE_NAME_ALREADY_EXISTS';
        break;
      case ApiResponseCodeEnum.EQUIPMENT_TYPE_UNIT_NAME_ALREADY_EXISTS:
        errorText = 'error.EQUIPMENT_TYPE_UNIT_NAME_ALREADY_EXISTS';
        break;
      case ApiResponseCodeEnum.TENANT_HAS_NO_SUBLOCATION_FEATURE:
        errorText = 'error.TENANT_HAS_NO_SUBLOCATION_FEATURE';
        break;
      case ApiResponseCodeEnum.SUBLOCATION_CANNOT_HAVE_CHILDREN:
        errorText = 'error.SUBLOCATION_CANNOT_HAVE_CHILDREN';
        break;
      case ApiResponseCodeEnum.SUBLOCATION_PARENT_NOT_STORE:
        errorText = 'error.SUBLOCATION_PARENT_NOT_STORE';
        break;
      case ApiResponseCodeEnum.CHECKLIST_HAS_SUBSCRIBERS:
        errorText = 'error.CHECKLIST_HAS_SUBSCRIBERS';
        break;
      case ApiResponseCodeEnum.SUBSCRIPTION_CHECKLIST_CODE_CLEAR:
        errorText = 'error.SUBSCRIPTION_CHECKLIST_CODE_CLEAR';
        break;
      case ApiResponseCodeEnum.CHECKLIST_SUBSCRIPTION_CODE_IS_INVALID:
        errorText = 'error.CHECKLIST_SUBSCRIPTION_CODE_IS_INVALID';
        break;
      case ApiResponseCodeEnum.CHECKLIST_IS_OWNED_BY_TENANT:
        errorText = 'error.CHECKLIST_IS_OWNED_BY_TENANT';
        break;
      case ApiResponseCodeEnum.CHECKLIST_SUBSCRIBER_HAS_DIFFERENT_BRAND:
        errorText = 'error.CHECKLIST_SUBSCRIBER_HAS_DIFFERENT_BRAND';
        break;
      case ApiResponseCodeEnum.CHECKLIST_IS_ALREADY_SUBSCRIBED:
        errorText = 'error.CHECKLIST_IS_ALREADY_SUBSCRIBED';
        break;
      case ApiResponseCodeEnum.CHECKLIST_CAN_NOT_BE_EDITED_NOT_CREATED_AT_LOCATION:
        errorText = 'error.CHECKLIST_CAN_NOT_BE_EDITED_NOT_CREATED_AT_LOCATION';
        break;
      case ApiResponseCodeEnum.CHECKLIST_DOES_NOT_EXIST_UNDER_LOCATION:
        errorText = 'error.CHECKLIST_DOES_NOT_EXIST_UNDER_LOCATION';
        break;
      case ApiResponseCodeEnum.EQUIPMENT_ASSIGNED_TO_LOCATION_CANT_BE_DELETED_DEACTIVATED:
        errorText = 'error.EQUIPMENT_ASSIGNED_TO_LOCATION_CANT_BE_DELETED_DEACTIVATED';
        break;
      case ApiResponseCodeEnum.CHECKLIST_EXPORT_ERROR:
        errorText = 'error.CHECKLIST_EXPORT_ERROR';
        break;
      case ApiResponseCodeEnum.CHECKLIST_CANNOT_CHANGE_ASSIGNED_WORKFLOW_TEMPLATE:
        errorText = 'error.CHECKLIST_CANNOT_CHANGE_ASSIGNED_WORKFLOW_TEMPLATE';
        break;
      case ApiResponseCodeEnum.CHECKLIST_CANNOT_CHANGE_INPUT_TYPE_OR_EXPECTED_VALUE_TYPE:
        errorText = 'error.CHECKLIST_CANNOT_CHANGE_INPUT_TYPE_OR_EXPECTED_VALUE_TYPE';
        break;
      case ApiResponseCodeEnum.UNSUPPORTED_WORKFLOW_UNIT:
        errorText = 'error.UNSUPPORTED_WORKFLOW_UNIT';
        break;
      case ApiResponseCodeEnum.CHECKLIST_OPTION_NAME_DUPLICATE:
        errorText = 'error.CHECKLIST_OPTION_NAME_DUPLICATE';
        details = null;
        break;

      //Locking checklist errors
      case ApiResponseCodeEnum.CHECKLIST_CANT_BE_LOCKED_CUSTOMIZATIONS_AND_CHILD_TASKS_EXIST:
      case ApiResponseCodeEnum.CHECKLIST_CANT_BE_LOCKED_TASKS_CUSTOMIZED_ALREADY:
      case ApiResponseCodeEnum.CHECKLIST_CANT_BE_LOCKED_TASKS_ADDED_AT_CHILD_LOCATIONS:
        showErrorMessage = false;
        break;

      case ApiResponseCodeEnum.OPS_TASK_SEVERITY_LEVEL_ERROR:
        errorText = 'error.OPS_TASK_SEVERITY_LEVEL_ERROR';
        break;
      case ApiResponseCodeEnum.OPS_TASK_DIFFICULTY_LEVEL_ERROR:
        errorText = 'error.OPS_TASK_DIFFICULTY_LEVEL_ERROR';
        break;
      case ApiResponseCodeEnum.OPS_TASK_AREA_ERROR:
        errorText = 'error.OPS_TASK_AREA_ERROR';
        break;
      case ApiResponseCodeEnum.OPS_TASK_TYPE_ERROR:
        errorText = 'error.OPS_TASK_TYPE_ERROR';
        break;
      case ApiResponseCodeEnum.PUBLISHED_TASK_STEPS_EDIT_NOT_ALLOWED:
        errorText = 'error.PUBLISHED_TASK_STEPS_EDIT_NOT_ALLOWED';
        break;
      case ApiResponseCodeEnum.OPS_TASK_NAME_TOO_BIG:
        errorText = 'error.OPS_TASK_NAME_TOO_BIG';
        break;
      case ApiResponseCodeEnum.HIERARCHY_DUPLICATE_NAME:
        errorText = 'error.HIERARCHY_DUPLICATE_NAME';
        break;

      // Reseller Manager Error Codes
      case ApiResponseCodeEnum.RESELLER_SUB_ROUTE_IS_DUPLICATE:
        errorText = 'error.RESELLER_SUB_ROUTE_IS_DUPLICATE';
        break;

      // Timer error messages
      case ApiResponseCodeEnum.TIMER_TEMPLATE_DUPLICATE_NAME:
        errorText = 'error.TIMER_TEMPLATE_DUPLICATE_NAME';
        break;

      // App release note messages
      case ApiResponseCodeEnum.APP_RELEASE_NOTE_DUPLICATE:
        errorText = 'error.APP_RELEASE_NOTE_DUPLICATE';
        break;

      //Orders error messages
      case ApiResponseCodeEnum.ORDERING_TEMPLATE_DUPLICATE_NAME:
        errorText = 'error.ORDERING_TEMPLATE_DUPLICATE_NAME';
        break;
      case ApiResponseCodeEnum.TEMPLATE_ITEM_COUNT_NOT_SUPPORTED:
        errorText = 'error.TEMPLATE_ITEM_COUNT_NOT_SUPPORTED';
        break;
      case ApiResponseCodeEnum.ORDERING_INVALID_DELIVERY_DATE:
        errorText = 'error.ORDERING_INVALID_DELIVERY_DATE';
        break;
      case ApiResponseCodeEnum.ORDERING_FAILED_ORDER_ALREADY_FOUND:
        errorText = 'error.ORDERING_FAILED_ORDER_ALREADY_FOUND';
        break;
      case ApiResponseCodeEnum.TEMPLATE_ITEM_LIMIT_REACHED:
        errorText = 'error.OGS_limit_error';
        interpolateParams = { limit: OGGetLimits.TEMPLATE_ITEMS_LIMIT };
        break;

      // Printer type error messages
      case ApiResponseCodeEnum.PRINTER_TYPE_DUPLICATE:
        errorText = 'error.PRINTER_TYPE_DUPLICATE';
        break;

      // location subscription code error messages
      case ApiResponseCodeEnum.LOCATION_ALREADY_HAS_ACTIVE_SUBSCRIPTIONS:
        errorText = 'error.LOCATION_ALREADY_HAS_ACTIVE_SUBSCRIPTIONS';
        break;
      case ApiResponseCodeEnum.STORE_NUMBER_INVALIDLENGTH:
        errorText = 'error.STORE_NUMBER_INVALIDLENGTH';
        break;
      case ApiResponseCodeEnum.LOCATION_ALREADY_HAS_SUBSCRIPTION_CODE:
        errorText = 'error.LOCATION_ALREADY_HAS_SUBSCRIPTION_CODE';
        break;
      case ApiResponseCodeEnum.LOCATION_ALREADY_HAS_SUBSCRIPTION_CODE_AT_HIGHER_LEVEL:
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.LOCATION_ALREADY_HAS_SUBSCRIPTION_CODE_AT_CHILD_LEVEL:
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.LOCATION_CANNOT_GENERATE_CODE:
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.LOCATION_CANNOT_SUBSCRIBE_TO_SUBSCRIPTION_CODE:
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.LOCATION_ALREADY_HAS_SUBSCRIPTIONS:
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.LOCATION_ALREADY_HAS_SUBSCRIPTIONS_AT_HIGHER_LEVEL:
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.LOCATION_ALREADY_HAS_SUBSCRIPTIONS_AT_LOWER_LEVEL:
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.LOCATION_SUBSCRIPTION_SUBSCRIBER_ALREADY_HAS_SCHEDULE_DEFINED:
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.LOCATION_SUBSCRIPTION_INVALID_NUTRITIONAL_FACT_PROFILES:
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.LOCATION_SUBSCRIPTION_ALREADY_ACTIVE_FOR_DIFFERENT_ACCOUNT:
        errorText = 'error.LOCATION_SUBSCRIPTION_ALREADY_ACTIVE_FOR_DIFFERENT_ACCOUNT';
        break;
      case ApiResponseCodeEnum.LOCATION_SUBSCRIPTION_SUBSCRIBER_AND_PUBLISHER_BRANDS_DO_NOT_MATCH:
        errorText = 'error.LOCATION_SUBSCRIPTION_SUBSCRIBER_AND_PUBLISHER_BRANDS_DO_NOT_MATCH';
        break;
      case ApiResponseCodeEnum.LOCATION_SUBSCRIPTION_CODE_INVALID:
        errorText = 'error.LOCATION_SUBSCRIPTION_CODE_INVALID';
        break;
      case ApiResponseCodeEnum.LOCATION_ALREADY_SUBSCRIBED_WITH_THE_SAME_CODE:
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.LOCATION_ALREADY_HAS_REJECTED_SUBSCRIPTION_FOR_THE_SAME_CODE:
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.FOLDER_CANNOT_BE_DELETED_IT_HAS_ACTIVE_SUBSCRIPTIONS:
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.LOCATION_HAS_A_SUBSCRIPTION_CODE:
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.LOCATION_SUBSCRIPTION_MOVE_SOURCE_AND_DESTINATION_ARE_EQUAL:
        errorText = 'error.LOCATION_SUBSCRIPTION_MOVE_SOURCE_AND_DESTINATION_ARE_EQUAL';
        break;
      case ApiResponseCodeEnum.LOCATION_SUBSCRIPTION_MOVE_SOURCE_AND_DESTINATION_INCOMPATIBLE_TENANT_ID:
        errorText = 'error.LOCATION_SUBSCRIPTION_MOVE_SOURCE_AND_DESTINATION_INCOMPATIBLE_TENANT_ID';
        break;

      ////////////////////////////////////////////////////////////////

      case ApiResponseCodeEnum.FILE_IMPORT_LOCATION_INCORRECT_FORMAT:
        errorText = 'error.FILE_IMPORT_LOCATION_INCORRECT_FORMAT';
        break;

      case ApiResponseCodeEnum.DEVICE_ID_COLUMN_MISSING:
        errorText = 'error.DEVICE_ID_COLUMN_MISSING';
        break;

      case ApiResponseCodeEnum.KNOWLEDGE_BASE_ZY_API_ERROR:
      case ApiResponseCodeEnum.KNOWLEDGE_BASE_NO_ZY_TOKNEN:
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.CONNECTION_TO_START_NOT_ALLOWED:
        errorText = 'error.CONNECTION_TO_START_NOT_ALLOWED';
        break;
      case ApiResponseCodeEnum.INVALID_LIQUID_EXPRESSION:
        errorText = 'error.INVALID_LIQUID_EXPRESSION';
        break;
      case ApiResponseCodeEnum.INFINITE_LOOP:
        errorText = 'error.INFINITE_LOOP';
        break;
      // Scale model error messages
      case ApiResponseCodeEnum.SCALE_MODEL_DUPLICATE:
        errorText = 'error.SCALE_MODEL_DUPLICATE';
        break;
      case ApiResponseCodeEnum.BATCH_PRINT_HAS_CUSTOMIZED_ITEMS:
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.BATCH_PRINT_DUPLICATE_NAME:
        errorText = 'error.BATCH_PRINT_DUPLICATE';
        break;
      case ApiResponseCodeEnum.CATALOG_NOT_ACCESSIBLE:
        showErrorMessage = false;
        break;
      case ApiResponseCodeEnum.ENTERPRISE_PORTAL_DISTRIBUTION_CENTERS_GENERAL_ERROR:
        showErrorMessage = false;
        break;
      default:
        errorText = this.getSpecificErrorText(err, url, method) ? this.getSpecificErrorText(err, url, method) : 'error.UNKNOWN_ERROR';
        break;
    }
    if (details) {
      showErrorMessage = false;
    }

    if (errorCode == ApiResponseCodeEnum.NO_ACTIVE_EQUIPMENT_TYPES) showErrorMessage = false;

    if (showErrorMessage) this.showErrorMessage(errorText, duration, interpolateParams);
  }

  /**
   * use this function when we need to show a specific error to the url where the same error code is returned to multiple services.
   * use includes to match the url ( ex: url.includes('accounts') && url.includes('locations')).
   * enpoints should also be Enums, for better readability and reusability.
   * @param url the service url
   */
  getSpecificErrorText(err, url, method): string {
    if (url.includes('menus') && method === HttpMethod.POST) {
      return 'error.MENU_CREATION_FAIL';
    } else if (url.includes('menuitemcategories') && method === HttpMethod.POST) {
      return 'error.CATEGORY_CREATION_FAIL';
    } else if (url.includes('products') && method === HttpMethod.POST) {
      return 'error.PRODUCT_CREATION_FAIL';
    } else if (url.includes('products') && method === HttpMethod.PUT) {
      return 'error.PRODUCT_UPDATE_FAIL';
    } else if (url.includes('ingredients') && method === HttpMethod.POST) {
      return 'error.INGREDIENT_CREATION_FAIL';
    } else if (url.includes('Orders') && method === HttpMethod.POST) {
      return 'error.ORDER_CREATION_FAIL';
    } else if (url.includes('locations') && method === HttpMethod.POST) {
      return 'error.LOCATION_CREATION_FAIL';
    } else if (url.includes('stores') && method === HttpMethod.POST) {
      return 'error.STORE_CREATION_FAIL';
    } else if (url.includes('locations') && method === HttpMethod.PUT) {
      return 'error.LOCATION_UPDATE_FAIL';
    } else if (url.includes('checklist') && (method === HttpMethod.GET || method === HttpMethod.POST || method === HttpMethod.PUT) && defined(err['error'] && err['error'].locationId)) {
      return 'error.LOCATION_ID_MISSING';
    } else if (url.includes('labelTemplates') && method === HttpMethod.POST) {
      return 'error.LABEL_TEMPLATE_FILE_IMPORT_FAIL';
    } else if (url.includes('labelTemplates') && method === HttpMethod.PUT) {
      return 'error.LABEL_TEMPLATE_FILE_IMPORT_FAIL';
    } else if (url.includes('populate') && method === HttpMethod.POST) {
      return 'error.FACTS_POPULATE_FAIL';
    } else if (url.includes('styles') && method === HttpMethod.POST) {
      return 'error.STYLE_CREATION_FAIL';
    } else if (url.includes('custom-fields') && method === HttpMethod.POST) {
      return 'error.CUSTOM_FIELD_CREATION_FAIL';
    } else if (url.includes('addons') && method === HttpMethod.POST) {
      return 'error.ADDON_CREATION_FAIL';
    } else if (url.includes('accounts') && method === HttpMethod.PUT) {
      return 'error.ACCOUNT_UPDATE_FAIL';
    }

    return '';
  }

  showErrorMessage(err: string, duration: number, interpolateParams?: Object) {
    this.snackbarService.openWarningSnackBarWithDuration(err, 'Ok', false, duration, interpolateParams);
  }
}
