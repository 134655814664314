import { Inject, Injectable } from '@angular/core';
import { AuthService } from '../../core/auth/auth.service';
import { deepClone, defined, isNullOrEmpty } from '../../shared/helpers/app.helpers';
import { Section, SidenavSection } from '../../shared/models/sidenavSection.model';
import { Module, ModulePage } from '../../shared/models/user-roles.model';
import { TokenModule } from './../../shared/models/tokenModule.model';
import * as Enumerable from 'linq-es2015';
import { userAuthModules } from '../config/user-auth-modules';
import { UserPreferenceService } from '../../shared/services/user-preference.service';
import { TenantTypeEnum } from '../../shared/models/tenant-type.model';
import { RUNTIME_CONFIGURATION } from '../../shared/tokens';
import { RuntimeConfiguration } from '../../shared/models/runtime-configuration.model';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  // This will store all the user configuration modules and pages
  // It should be manually updated when new page/module is created
  loginApiService = '/Login';
  authorizedRoutes: string[] = [];
  authorizedPages: ModulePage[] = [];
  allowedSections: SidenavSection[] = [];
  currentUserModules: TokenModule[];
  defaulLinksToRedirect = ['/menumanager/menus', '/menumanager/menuitems', '/menumanager/ingredients'];

  constructor(private authService: AuthService, private userPreferenceService: UserPreferenceService, @Inject(RUNTIME_CONFIGURATION) public runtimeConfiguration: RuntimeConfiguration) {}

  setCurrenttUserModules(currentUserModules: TokenModule[]) {
    this.currentUserModules = currentUserModules;
  }

  getCurrentUserModules(): TokenModule[] {
    return this.currentUserModules;
  }

  setAuthorizedPages() {
    let allModules: Module[] = userAuthModules.allModules as Module[];
    let allowedModules: Module[];
    if (this.authService.getIsReseller()) {
      allModules = allModules.filter((module) => module.isAllowedForReseller !== false);
    }
    allowedModules = this.filterModulesAndPagesBasedOnAccountPermissions(allModules);
    allowedModules = this.filterModulesAndPagesBasedOnUserRolePermissions(allowedModules);

    this.fillAuthorizedPages(allowedModules);
    this.fillAuthorizedRoutes(this.authorizedPages);
  }

  getAuthorizedPages(): ModulePage[] {
    return this.authorizedPages;
  }

  getAuthorizedRoutes(): string[] {
    return this.authorizedRoutes;
  }

  getAllSidenavSection(): SidenavSection[] {
    return userAuthModules.allSidenavSection as any[];
  }

  setAllowedSections() {
    this.allowedSections = [];

    let allowedPagesByLink: string[] = [];
    let tenantTypeId = this.authService.getAccountTenantTypeId();
    // First we will get the list of allowed pages by Link .
    allowedPagesByLink = this.getAllowedPagesByLink();
    // Then we will get the list of all available SideNav stored in the json config file.
    const allSections: SidenavSection[] = deepClone(this.getAllSidenavSection());

    // Then for each allowed main section we will filter the available subsections based on the allowed PageIds.
    allSections.forEach((section) => {
      if (section.alwaysVisible) {
        this.allowedSections.push(section);
      } else {
        if (!section.hasSubSections) {
          if (section.mainSection.link !== undefined) {
            if (allowedPagesByLink.some((regexString) => new RegExp(regexString).test(section.mainSection.link))) {
              this.allowedSections.push(section);
            } else {
              // Check for tabs
              if (section.childTabsLinks !== undefined) {
                const tabLink = section.childTabsLinks.find((tablink) => allowedPagesByLink.some((regexString) => new RegExp(regexString).test(tablink)));
                if (tabLink !== undefined) {
                  section.mainSection.link = tabLink;
                  this.allowedSections.push(section);
                }
              }
            }
          }
        } else {
          section.subSections = this.getAllowedSubsections(section.subSections, allowedPagesByLink);
          if (section.subSections.length > 0) {
            this.allowedSections.push(section);
          }
        }
      }
    });
  }

  getAllowedSections(): SidenavSection[] {
    // First Check  if we nee to set the user allowed modules again
    // const impersonationMode = this.authService.getImpersonationMode();
    // if (impersonationMode !== null) {
    //   this.setUserAuthorizedModules(impersonationMode.modules);
    // } else {
    //   if (this.authService.getCurrentUserModules().length > 0 && this.getAuthorizedPages().length === 0) {
    //     this.setUserAuthorizedModules(this.authService.getCurrentUserModules());
    //   }
    // }

    if (this.authService.getCurrentUserModules().length > 0 && this.getAuthorizedPages().length === 0) {
      this.setUserAuthorizedModules(this.authService.getCurrentUserModules());
    }
    return this.allowedSections;
  }

  getAllowedDashboardSections(): SidenavSection[] {
    var sideNavSections = this.getAllowedSections().filter((item) => item.order !== 0);
    var dashboardSections: SidenavSection[] = [];

    sideNavSections
      .filter((section) => !section.mainSection.notIncludedInDashboard)
      .forEach((section) => {
        // if true=> subsections to be splitted based DashboardSection Field
        if (section.splitSubSections) {
          var subSections = section.subSections; //original subsections
          section.dashboardSections.forEach((dashboardSectionTitle) => {
            var dashboardSection = deepClone(section);
            dashboardSection.mainSection.title = dashboardSectionTitle; // change title
            dashboardSection.mainSection.noTitleCase = dashboardSection.subSections.find((x) => x.dashboardSection === dashboardSectionTitle)?.noTitleCase;
            dashboardSection.subSections = subSections.filter((ss) => ss.dashboardSection === dashboardSectionTitle); //get related sub sections
            if (dashboardSection.subSections[0]) {
              dashboardSection.icon = dashboardSection.subSections[0].icon;
            }
            if (dashboardSection.subSections && dashboardSection.subSections.length > 0) dashboardSections.push(dashboardSection);
          });
        } else dashboardSections.push(section);
      });
    return dashboardSections;
  }

  getAllowedPagesByLink(): string[] {
    const allowedPagesByLink: string[] = [];
    let authorizedPages: ModulePage[] = [];
    authorizedPages = this.getAuthorizedPages();
    authorizedPages.forEach((x) => {
      if (x && x.link !== undefined) {
        allowedPagesByLink.push(x.link);
      }
    });
    return allowedPagesByLink;
  }

  getAllowedSubsections(subSections: Section[], allowedPagesByLink: string[]): Section[] {
    let allowedSubsection: Section[] = [];
    allowedSubsection = subSections.filter((subsection) => {
      const hasLink = defined(subsection.link);
      const linkAccessible = hasLink
        ? allowedPagesByLink.some((modulePageLink) => {
            const match = subsection.link.match(modulePageLink);
            return defined(match) && match[0] === subsection.link;
          })
        : false;
      return (hasLink && linkAccessible) || hasLink === false;
    });

    return allowedSubsection;
  }

  setUserAuthorizedModules(currentUserModules: TokenModule[]) {
    currentUserModules = currentUserModules.filter((cm) => cm.IsVisible === true);
    this.setCurrenttUserModules(currentUserModules);

    if (currentUserModules.length === 0) {
      this.clearUserRoles();
    } else {
      this.setAuthorizedPages();
      this.setAllowedSections();
    }
  }

  clearUserRoles() {
    this.authorizedPages = [];
    this.authorizedRoutes = [];
    this.allowedSections = [];
  }

  refreshUserAllowedModules() {
    // First Check  if we need to set the user allowed modules again
    if (this.authService.getCurrentUserModules().length > 0 && this.getAuthorizedPages().length === 0) {
      this.setUserAuthorizedModules(this.authService.getCurrentUserModules());
    }
  }

  // Get default page to redirect based on authorized pages
  getDefaultPageToRedirect(): string {
    const url = '/unauthorized';
    // First Check  if we need to set the user allowed modules again
    this.refreshUserAllowedModules();

    let allowedSection: SidenavSection[];
    allowedSection = this.getAllowedSections();
    if (this.authService.getIsReseller()) {
      return 'accountmanager/accounts';
    } else {
      if (allowedSection.length === 0) {
        return url;
      }

      // Check first if default link(Menu manager) exist to redirect to;else we will be using the existing logic
      const foundSection = allowedSection.find((item) => {
        return item.mainSection.link !== undefined && this.defaulLinksToRedirect.includes(item.mainSection.link);
      });

      if (foundSection !== undefined) {
        return foundSection.mainSection.link;
      }

      // Find if exist the first mainSection whose link is undefined
      const firstMainSection = allowedSection.find((item) => item.mainSection.link !== undefined);
      if (firstMainSection !== undefined) {
        return firstMainSection.mainSection.link;
      }
      // find the first subsection whose link is undefined
      const hasSubsection = allowedSection.find((item) => item.hasSubSections);
      if (hasSubsection !== undefined) {
        const subsection = hasSubsection.subSections.find((page) => page.link !== undefined);
        if (subsection !== undefined) {
          return subsection.link;
        }
      }
    }
    return url;
  }

  resetImpersonationTokens() {
    return new Promise((resolve) => {
      if (this.authService.getParentAuthToken() && this.authService.getParentRefreshToken()) {
        this.authService.setAuthToken(this.authService.getParentAuthToken());
        this.authService.setRefreshToken(this.authService.getParentRefreshToken());
        this.authService.removeParentAuthToken();
        this.authService.removeParentRefreshToken();
        resolve(true);
      } else {
        resolve(false);
      }
    });
  }

  resetModulesAndPermissions(reloadPage: boolean = false) {
    this.clearUserRoles();
    this.setUserAuthorizedModules(this.authService.getCurrentUserModules());
    if (reloadPage) {
      this.authService.setReloadPage();
    } else {
      this.authService.toggleImpersonationMode(false);
    }
    this.userPreferenceService.getUserPreference(this.authService.getCurrentUserAccountId());
  }

  private filterModulesAndPagesBasedOnAccountPermissions(modules: Module[]): Module[] {
    modules = this.filterModulesBasedOnAccountPermissions(modules);
    this.filterModulesPagesBasedOnAccountPermissions(modules);

    return modules;
  }

  private filterModulesBasedOnAccountPermissions(modules: Module[]): Module[] {
    modules = deepClone(
      modules.filter(
        (module) =>
          (!module.isForPublisher || (this.authService.getIsPublisher() && module.isForPublisher === true)) &&
          (!module.isForNewSubscriptionFlow || (this.authService.getUseNewSubscriptionFlow() && module.isForNewSubscriptionFlow === true)) &&
          (!module.isForOldSubscriptionFlow || (this.authService.getUseNewSubscriptionFlow() === false && module.isForOldSubscriptionFlow === true))
      )
    );
    return modules;
  }

  private filterModulesPagesBasedOnAccountPermissions(modules: Module[]): void {
    modules.forEach((m: Module) => {
      //m.pages = Enumerable.asEnumerable(m.pages).Where(p => (p.link != '/analytics/temp-dashboard-v2' && environment.production) || !environment.production).ToArray();

      m.pages = Enumerable.asEnumerable(m.pages)
        .Where((p) => p.authorizedBylocalKey !== null)
        .Select((p: ModulePage, index: number) => {
          if (!p.authorizedBylocalKey && !p.authorizedByEnvironmentKey) return p;
          else {
            let needsEnvironmentKeyValidation: Boolean = p.authorizedByEnvironmentKey ? true : false;

            if (needsEnvironmentKeyValidation) {
              for (let _key of p.authorizedByEnvironmentKey) {
                if (this.runtimeConfiguration[_key] !== true) {
                  return;
                }
              }
            }

            if (p.authorizedBylocalKey) {
              for (let _key of p.authorizedBylocalKey) {
                var keyValue = localStorage.getItem(_key);
                if (!isNullOrEmpty(keyValue) && keyValue === 'true') {
                  return p;
                }
              }
            } else return p;
          }
        })
        .Where((p) => defined(p))
        .ToArray();
    });
  }

  private filterModulesAndPagesBasedOnUserRolePermissions(modules: Module[]): Module[] {
    modules = this.filterModulesBasedOnUserRolePermissions(modules);
    this.filterModulesPagesBasedOnUserRolePermissions(modules);

    return modules;
  }

  private filterModulesBasedOnUserRolePermissions(modules: Module[]): Module[] {
    const currentUserModuleNames = this.getCurrentUserModules().map((_module) => _module.Name);
    modules = modules.filter((module) => module.isAllowedToAll || currentUserModuleNames.includes(module.moduleName));
    return modules;
  }

  private filterModulesPagesBasedOnUserRolePermissions(modules: Module[]): void {
    modules.forEach((module) => {
      module.pages = module.pages.filter((page) => {
        let allowedToAccessPage = true;

        if (page.userModulesPermissions) {
          const userHasModulesPermissionsByDefault = false;
          const userHasModulePermissionsByDefault = false;

          allowedToAccessPage = page.userModulesPermissions.reduce((userHasModulesPermissions, userModulePermissions) => {
            const userHasModulePermissions = userModulePermissions.permissions.reduce((hasModulePermissions, permission) => {
              const userHasModulePermission = this.authService.hasPermission(userModulePermissions.module, permission);

              return hasModulePermissions || userHasModulePermission;
            }, userHasModulePermissionsByDefault);

            return userHasModulesPermissions || userHasModulePermissions;
          }, userHasModulesPermissionsByDefault);
        }

        return allowedToAccessPage;
      });
    });
  }

  private fillAuthorizedPages(modules: Module[]): void {
    this.authorizedPages = [];
    modules.forEach((module) => this.authorizedPages.push(...module.pages));
  }

  private fillAuthorizedRoutes(authorizedPages: ModulePage[]): void {
    authorizedPages.forEach((page) => {
      if (page && page.link !== undefined) {
        this.authorizedRoutes.push(page.link);
      }
    });
  }
}
