import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'nome-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent implements OnInit {
  externalWindow: any;
  constructor(private dialogRef: MatDialogRef<CookieConsentComponent>) {}
  ngOnInit() {}

  acceptCookies() {
    this.dialogRef.close(true);
  }
}
