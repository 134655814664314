<div class="row">
  <div class="col-11">
    <h1 class="mat-dialog-title dialog-title">{{ data.title }}</h1>
  </div>
  <div (click)="closeDialog()" class="col-1">
    <mat-icon class="float-right pointer">close</mat-icon>
  </div>
</div>
<div class="row">
  <div class="col">
    <h6>{{ data.subtitle | translate }}</h6>
  </div>
</div>
<div class="row mt-2">
  <div class="col">
    <nome-custom-chips-list [list]="chipsToDisplay" [listLimit]="0"></nome-custom-chips-list>
  </div>
</div>
