import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Observable, Subject } from 'rxjs';
import {
  CatalogCreatedNotification,
  CatalogDeletedNotification,
  CatalogEditedNotification,
  MoveLocationNotification,
  ProductCreatedNotification,
  ReportOnDemandNotification,
  CatalogProductsAssignedUnassignedNotification,
  ProductDeletedNotification,
  ProductEditedNotification,
  NodeCatalogsAssignedUnassignedNotification,
  NodeCatalogUnassignedNotification,
  NodeCatalogsUnassignedNotification,
  CatalogBulkAddNotification,
  ADFEditedNotification,
  CatalogProductUnassignedNotification,
  PublishAllMenuNotification
} from '../../shared/models/notification.model';
import { getResellerUrl } from '../../shared/helpers/api-helper';
import { environment } from '../../../environments/environment';
import { SignalRMethodNamesEnum } from './enum/signal-r-enum';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  hubUrl: string;
  connection: signalR.HubConnection;
  private _notifyMoveLocation$: Subject<MoveLocationNotification> = new Subject<MoveLocationNotification>();
  private _notifyReportOnDemand$: Subject<ReportOnDemandNotification> = new Subject<ReportOnDemandNotification>();

  private _notifyCatalogCreated: Subject<CatalogCreatedNotification> = new Subject<CatalogCreatedNotification>();
  private _notifyCatalogDeleted: Subject<CatalogDeletedNotification> = new Subject<CatalogDeletedNotification>();
  private _notifyCatalogEdited: Subject<CatalogEditedNotification> = new Subject<CatalogEditedNotification>();
  private _notifyCatalogBulkAdd: Subject<CatalogBulkAddNotification> = new Subject<CatalogBulkAddNotification>();
  private _notifyCatalogProductsAssignedUnassigned: Subject<CatalogProductsAssignedUnassignedNotification> = new Subject<CatalogProductsAssignedUnassignedNotification>();
  private _notifyCatalogProductUnassigned: Subject<CatalogProductUnassignedNotification> = new Subject<CatalogProductUnassignedNotification>();
  private _notifyProductCreated: Subject<ProductCreatedNotification> = new Subject<ProductCreatedNotification>();
  private _notifyProductDeleted: Subject<ProductDeletedNotification> = new Subject<ProductDeletedNotification>();
  private _notifyProductsBulkDeleted: Subject<ProductDeletedNotification> = new Subject<ProductDeletedNotification>();
  private _notifyProductEdited: Subject<ProductEditedNotification> = new Subject<ProductEditedNotification>();
  private _notifyNodeCatalogsAssignedUnassigned: Subject<NodeCatalogsAssignedUnassignedNotification> = new Subject<NodeCatalogsAssignedUnassignedNotification>();
  private _notifyNodeCatalogUnassigned: Subject<NodeCatalogUnassignedNotification> = new Subject<NodeCatalogUnassignedNotification>();
  private _notifyNodeCatalogsUnassigned: Subject<NodeCatalogsUnassignedNotification> = new Subject<NodeCatalogsUnassignedNotification>();
  private _notifyADFEdited: Subject<ADFEditedNotification> = new Subject<ADFEditedNotification>();
  private _notifyAllMenusPublished: Subject<PublishAllMenuNotification> = new Subject<PublishAllMenuNotification>();

  constructor() {
    let url = getResellerUrl(environment);
    this.hubUrl = `${url}/notificationhub`;
  }

  public get moveLocationNotification(): Observable<MoveLocationNotification> {
    return this._notifyMoveLocation$;
  }

  public get reportOnDemandNotification(): Observable<ReportOnDemandNotification> {
    return this._notifyReportOnDemand$;
  }

  //#region Catalog Notifications
  public get catalogCreatedNotification(): Observable<CatalogCreatedNotification> {
    return this._notifyCatalogCreated;
  }
  public get catalogDeletedNotification(): Observable<CatalogDeletedNotification> {
    return this._notifyCatalogDeleted;
  }
  public get catalogEditedNotification(): Observable<CatalogEditedNotification> {
    return this._notifyCatalogEdited;
  }
  public get catalogBulkAddNotification(): Observable<CatalogBulkAddNotification> {
    return this._notifyCatalogBulkAdd;
  }
  public get catalogProductsAssignedUnassignedNotification(): Observable<CatalogProductsAssignedUnassignedNotification> {
    return this._notifyCatalogProductsAssignedUnassigned;
  }
  public get catalogProductUnassignedNotification(): Observable<CatalogProductUnassignedNotification> {
    return this._notifyCatalogProductUnassigned;
  }

  public get nodeCatalogsAssignedUnassignedNotification(): Observable<NodeCatalogsAssignedUnassignedNotification> {
    return this._notifyNodeCatalogsAssignedUnassigned;
  }
  public get nodeCatalogUnassignedNotification(): Observable<NodeCatalogUnassignedNotification> {
    return this._notifyNodeCatalogUnassigned;
  }
  public get nodeCatalogsUnassignedNotification(): Observable<NodeCatalogsUnassignedNotification> {
    return this._notifyNodeCatalogsUnassigned;
  }

  //#endregion End Catalog Notifications
  public get productCreatedNotification(): Observable<ProductCreatedNotification> {
    return this._notifyProductCreated;
  }
  public get productDeletedNotification(): Observable<ProductCreatedNotification> {
    return this._notifyProductDeleted;
  }
  public get productsBulkDeletedNotification(): Observable<ProductCreatedNotification> {
    return this._notifyProductsBulkDeleted;
  }

  public get productEditedNotification(): Observable<ProductCreatedNotification> {
    return this._notifyProductEdited;
  }

  public get adfEditedNotification(): Observable<ADFEditedNotification> {
    return this._notifyADFEdited;
  }

  public get notifyAllMenusPublished(): Observable<PublishAllMenuNotification> {
    return this._notifyAllMenusPublished;
  }

  public async initiateSignalrConnection(accountId: number): Promise<void> {
    try {
      if (this.connection) {
        this.removeSignalrConnection();
      }

      let url = this.hubUrl + '?accountId=' + accountId.toString();

      this.connection = new signalR.HubConnectionBuilder()
        .withUrl(url, {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets
        })
        .withAutomaticReconnect()
        .build();
      await this.connection.start();
    } catch (error) {}
  }

  // Invoke Disconnect function on server which will then send a Disconnect notification back to
  // client to stop the specific connection.
  public removeSignalrConnection() {
    if (this.connection) {
      this.connection.invoke('Disconnect').catch((error: any) => {});
      this.connection.stop();
    }
  }

  public isConnected(): boolean {
    return this.connection && this.connection.state === signalR.HubConnectionState.Connected;
  }

  public setSignalrClientMethods(accountId: number, registeredNotifications: SignalRMethodNamesEnum[]): void {
    if (!this.isConnected) {
      this.initiateSignalrConnection(accountId);
    }
    registeredNotifications.forEach((notification) => {
      switch (notification) {
        case SignalRMethodNamesEnum.MOVE_LOCATION_NOTIFICATION:
          this.connection.on(SignalRMethodNamesEnum.MOVE_LOCATION_NOTIFICATION, (obj: MoveLocationNotification) => {
            this._notifyMoveLocation$.next(obj);
          });
          break;
        case SignalRMethodNamesEnum.REPORT_ON_DEMAND:
          this.connection.on(SignalRMethodNamesEnum.REPORT_ON_DEMAND, (obj: ReportOnDemandNotification) => {
            this._notifyReportOnDemand$.next(obj);
          });
          break;
        //Catalog
        case SignalRMethodNamesEnum.CATALOG_CREATED_EVENT_NOTIFICATION:
          this.connection.on(SignalRMethodNamesEnum.CATALOG_CREATED_EVENT_NOTIFICATION, (obj: CatalogCreatedNotification) => {
            this._notifyCatalogCreated.next(obj);
          });
          break;
        case SignalRMethodNamesEnum.CATALOG_EDITED_EVENT_NOTIFICATION:
          this.connection.on(SignalRMethodNamesEnum.CATALOG_EDITED_EVENT_NOTIFICATION, (obj: CatalogEditedNotification) => {
            this._notifyCatalogEdited.next(obj);
          });
          break;
        case SignalRMethodNamesEnum.CATALOG_DELETED_EVENT_NOTIFICATION:
          this.connection.on(SignalRMethodNamesEnum.CATALOG_DELETED_EVENT_NOTIFICATION, (obj: CatalogDeletedNotification) => {
            this._notifyCatalogDeleted.next(obj);
          });
          break;
        case SignalRMethodNamesEnum.PRODUCT_CREATED_EVENT_NOTIFICATION:
          this.connection.on(SignalRMethodNamesEnum.PRODUCT_CREATED_EVENT_NOTIFICATION, (obj: CatalogDeletedNotification) => {
            this._notifyProductCreated.next(obj);
          });
          break;
        case SignalRMethodNamesEnum.CATALOG_PRODUCTS_ASSIGNED_UNASSIGNED_NOTIFICATION:
          this.connection.on(SignalRMethodNamesEnum.CATALOG_PRODUCTS_ASSIGNED_UNASSIGNED_NOTIFICATION, (obj: CatalogProductsAssignedUnassignedNotification) => {
            this._notifyCatalogProductsAssignedUnassigned.next(obj);
          });
          break;
        case SignalRMethodNamesEnum.CATALOG_PRODUCT_UNASSIGNED_EVENT_NOTIFICATION:
          this.connection.on(SignalRMethodNamesEnum.CATALOG_PRODUCT_UNASSIGNED_EVENT_NOTIFICATION, (obj: CatalogProductUnassignedNotification) => {
            this._notifyCatalogProductUnassigned.next(obj);
          });
          break;
        case SignalRMethodNamesEnum.PRODUCT_DELETED_EVENT_NOTIFICATION:
          this.connection.on(SignalRMethodNamesEnum.PRODUCT_DELETED_EVENT_NOTIFICATION, (obj: CatalogDeletedNotification) => {
            this._notifyProductDeleted.next(obj);
          });
          break;
        case SignalRMethodNamesEnum.PRODUCT_BULK_DELETED_EVENT_NOTIFICATION:
          this.connection.on(SignalRMethodNamesEnum.PRODUCT_BULK_DELETED_EVENT_NOTIFICATION, (obj: CatalogDeletedNotification) => {
            this._notifyProductsBulkDeleted.next(obj);
          });
          break;
        case SignalRMethodNamesEnum.PRODUCT_EDITED_EVENT_NOTIFICATION:
          this.connection.on(SignalRMethodNamesEnum.PRODUCT_EDITED_EVENT_NOTIFICATION, (obj: CatalogDeletedNotification) => {
            this._notifyProductEdited.next(obj);
          });
          break;
        case SignalRMethodNamesEnum.NODE_CATALOGS_ASSIGNED_UNASSIGNED_EVENT_NOTIFICATION:
          this.connection.on(SignalRMethodNamesEnum.NODE_CATALOGS_ASSIGNED_UNASSIGNED_EVENT_NOTIFICATION, (obj: NodeCatalogsAssignedUnassignedNotification) => {
            this._notifyNodeCatalogsAssignedUnassigned.next(obj);
          });
          break;
        case SignalRMethodNamesEnum.CATALOG_UNASSIGNED_FROM_NODE_EVENT_NOTIFICATION:
          this.connection.on(SignalRMethodNamesEnum.CATALOG_UNASSIGNED_FROM_NODE_EVENT_NOTIFICATION, (obj: NodeCatalogUnassignedNotification) => {
            this._notifyNodeCatalogUnassigned.next(obj);
          });
          break;
        case SignalRMethodNamesEnum.CATALOGS_UNASSIGNED_FROM_NODE_EVENT_NOTIFICATION:
          this.connection.on(SignalRMethodNamesEnum.CATALOGS_UNASSIGNED_FROM_NODE_EVENT_NOTIFICATION, (obj: NodeCatalogsUnassignedNotification) => {
            this._notifyNodeCatalogsUnassigned.next(obj);
          });
          break;
        case SignalRMethodNamesEnum.CATALOG_BULK_ADD_EVENT_NOTIFICATION:
          this.connection.on(SignalRMethodNamesEnum.CATALOG_BULK_ADD_EVENT_NOTIFICATION, (obj: CatalogBulkAddNotification) => {
            this._notifyCatalogBulkAdd.next(obj);
          });
          break;
        case SignalRMethodNamesEnum.LOCATION_ADF_ENABLED_NOTIFICATION:
          this.connection.on(SignalRMethodNamesEnum.LOCATION_ADF_ENABLED_NOTIFICATION, (obj: ADFEditedNotification) => {
            this._notifyADFEdited.next(obj);
          });
          break;
        case SignalRMethodNamesEnum.PUBLISH_ALL_MENUS_NOTIFICATION:
          this.connection.on(SignalRMethodNamesEnum.PUBLISH_ALL_MENUS_NOTIFICATION, (obj: PublishAllMenuNotification) => {
            this._notifyAllMenusPublished.next(obj);
          });
          break;
      }
    });
  }
  public disconnectSignalrClientMethods(registeredNotifications: SignalRMethodNamesEnum[]): void {
    registeredNotifications.forEach((notification) => {
      this.connection.off(notification);
    });
  }
}
