import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'nome-login-footer-message',
  templateUrl: './login-footer-message.component.html',
  styleUrls: ['./login-footer-message.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, TranslateModule]
})
export class LoginFooterMessageComponent {
  todayDate = new Date();
  portalName = this.themeService.getPortalTitle() + (this.themeService.resellerHasTradeMark() ? '™' : '');
  adTheme = this.themeService.isADTheme();

  constructor(private themeService: ThemeService) {}
}
