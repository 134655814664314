<mat-form-field [formGroup]="form" [ngClass]="formFieldClasses" [floatLabel]="floatLabel">
  <mat-label *ngIf="labelHiddenIfOptionSelected ? !optionSelected : true">{{ labelTranslationKey | translate | titlecase }}</mat-label>
  <mat-select
    [multiple]="multiple"
    id="dropdownWithSearch"
    formControlName="selectedOption"
    (openedChange)="onOpenedChange($event)"
    (selectionChange)="onSelectionChange($event)"
    [disableOptionCentering]="disableOptionCentering"
    [ngClass]="selectClasses"
    [required]="required"
    [panelClass]="panelClasses || 'flex-direction-column'"
  >
    <mat-select-trigger *ngIf="selectTriggerTemplate">
      <ng-container [ngTemplateOutlet]="selectTriggerTemplate"></ng-container>
    </mat-select-trigger>

    <mat-form-field floatLabel="never" class="input-wrapper">
      <input
        formControlName="searchText"
        (keydown)="isSpace($event) ? $event.stopPropagation() : null"
        autocomplete="off"
        matInput
        [placeholder]="'search' | translate | titlecase"
        (input)="filter($event.target.value)"
        (keydown.enter)="$event.stopPropagation(); emitOnEnter(form.controls['searchText'].value)"
      />
    </mat-form-field>

    <div *ngIf="clearSelectionVisible" class="text-right p-1">
      <button
        mat-button
        class="text-center dropdown-clickable-text mr-1"
        [disabled]="virtualScrollEnabled && !this.form.get('selectedOption').value"
        [disableRipple]="true"
        color="accent"
        (click)="deselectAll($event, true)"
      >
        {{ 'clear_selection' | translate | titlecase }}
      </button>
    </div>

    <mat-checkbox *ngIf="selectAllVisible" class="select-all-checkbox" [checked]="selectAll.checked" [indeterminate]="selectAll.indeterminate" (change)="onSelectAllChange($event)">{{
      selectAllTranslationKey | translate | titlecase
    }}</mat-checkbox>

    <ng-container *ngIf="virtualScrollEnabled; else defaultUsage">
      <cdk-virtual-scroll-viewport #scrollViewport itemSize="48" [minBufferPx]="300" [maxBufferPx]="600">
        <div class="options-wrapper">
          <mat-option *cdkVirtualFor="let option of filteredArray; trackBy: trackByIndex; templateCacheSize: 0" [value]="option.value" (click)="setSelectedItemIndex(option.value)">
            {{ option.viewValue }}
          </mat-option>
          <!-- We added this extra option in order to preserve the selected value inside mat-select-trigger -->
          <mat-option class="d-none" [value]="this.form.get('selectedOption').value">
            {{ this.form.get('selectedOption').value }}
          </mat-option>
        </div>
      </cdk-virtual-scroll-viewport>
    </ng-container>
    <ng-template #defaultUsage>
      <div class="options-wrapper" nomeInfiniteScroll (bottomReached)="onBottomReached()">
        <mat-option *ngFor="let option of options" [value]="option.value" [class.d-none]="!optionsVisibility[option.value]">
          {{ translateOptions?.translate ? (option.viewValue | translateAndCase : translateOptions.casing) : option.viewValue }}
        </mat-option>
        <!-- in case nothing from lazy loaded list is selected mat-select hides mat-select trigger section even though is customised-->
        <!-- this workaround is added to keep the mat-select trigger visible even when nothing is selected -->
        <mat-option *ngIf="addZeroOptionToSolveHiddenMatSelectTrigger" class="hidden" [value]="0"> this is me 0 </mat-option>
      </div>
    </ng-template>

    <div class="text-right pt-2 pb-2" *ngIf="applyButtonVisible">
      <button [disabled]="applyButtonDisabled" (click)="onApplyButtonClick()" mat-button class="dropdown-clickable-text mr-3" [disableRipple]="true" color="accent">
        {{ 'apply' | translate | titlecase }}
      </button>
    </div>
  </mat-select>
</mat-form-field>
