import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Component, OnInit, Inject, Injector, ComponentFactoryResolver } from '@angular/core';
import { Router } from '@angular/router';
// import { ReleaseNotesTemplateComponent } from '../../../modules/release-manager/release-notes-template/release-notes-template.component';
import { environment } from '../../../../environments/environment';
import { ResellerCheckService } from '../../services/reseller-check.service';

@Component({
  selector: 'nome-update-app',
  templateUrl: './update-app.component.html',
  styleUrls: ['./update-app.component.scss']
})
export class UpdateAppComponent implements OnInit {
  externalWindow: any;
  baseServiceUrl = `${environment.baseAPIUrl}${environment.version}/accounts/`;
  constructor(private dialogRef: MatDialogRef<UpdateAppComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public router: Router, public resellerCheckService: ResellerCheckService) {}
  isTransact: boolean;
  ngOnInit() {
    this.isTransact = this.resellerCheckService.isTransactReseller();
  }

  getUpdatedApp() {
    this.dialogRef.close(true);
  }

  updateLater() {
    this.dialogRef.close(false);
  }

  redirectToReleaseNotes() {
    const url = this.router.serializeUrl(this.router.createUrlTree(['releasemanager/release-notes-template']));
    window.open(url, '_blank');
  }
}
