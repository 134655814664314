import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { Observable, of } from 'rxjs';
import { UnsavedChangesDialogComponent } from '../../shared/components/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { ComponentCanDeactivate } from './component-can-deactivate';

@Injectable()
export class CanDeactivateGuard  {
  constructor(private matDialog: MatDialog) {}
  canDeactivate(component: ComponentCanDeactivate): Observable<boolean> {
    if (component.dirty && !component.saved) {
      return this.matDialog.open(UnsavedChangesDialogComponent).afterClosed();
    }
    return of(true);
  }
}
