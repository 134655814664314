import { ResellerApiUrl } from '../app/shared/models/reseller-api-url.model';
import { ResellerUrls } from '../app/shared/models/reseller-urls.model';
import { getResellerApiUrl } from '../app/shared/helpers/api-helper';

let basePath = document.location.origin.split(document.location.protocol + '//' + document.location.host)[1] === '/' ? '' : document.location.origin.split(document.location.protocol + '//' + document.location.host)[1];

export const environment = {
  local: false,
  staging: true,
  production: false,
  runtimeConfigurationFileName: 'runtime-configuration-staging',
  assets: basePath + '/assets/',
  baseAPIUrl: null,
  hardwareManagerBaseAPIUrl: 'http://app-hardwaremanager-stage.azurewebsites.net/api/',
  ocelotAPIUrl: 'https://inventory-staging.gotocme.com/',
  inventoryAPIUrl: 'https://inventory-staging.gotocme.com/inventory/',
  version: 'v1.0',
  version2: 'v2.0',
  editorBaseUrl: 'https://cme-label-designer.gotocme.com',
  editorApiEndpoint: 'ThermalLabelWebEditorHandler/ProcessRequest',
  googleAPIKey: 'AIzaSyCnZiAj8eIhurGF2XS2vcQIV-pNFR_EbV0',
  captchaKey: '6Ldgkm4aAAAAAK5_OWs3VF8Zx1BpF2RF6cJfaBl8',
  checklistUrl: 'https://wa.staging.zippyyum.com',
  zippyYumPortalDomain: 'zippyyum_portal.staging.zippyyum.com',
  transactPortalDomain: 'nome_portal_staging.gotocme.com',
  zippyYumKBUrl: 'https://wa.staging.zippyyum.com/kb',
  checklistHomePath: '/Checklist/nomechecklists',
  workflowapi: 'https://nome_workflow_staging.gotocme.com/api/',
  menuIcons: basePath + '/assets/images/menu-icons/light/',
  resellersUrls: [
    new ResellerUrls('https://nome_portal_staging.gotocme.com/', new ResellerApiUrl('https://nome_api_web_staging.gotocme.com/api/')),
    new ResellerUrls('http://zippyyum_portal.staging.zippyyum.com/', new ResellerApiUrl('https://nome-api-web-staging.gotocme.com/api/', true))
  ],
  keycloakURL: 'https://keycloak-staging-ha.gotocme.com:9001',
  OIDCRealm: 'ZY',
  OIDCClient: 'customer-portal',
  COBROWSE_LICENSE_KEY: 'ImDNhyagiVzdpw'
};

environment.baseAPIUrl = getResellerApiUrl(environment);
