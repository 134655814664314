import { SafeUrl } from '@angular/platform-browser';
import { CustomFieldTypeEnum } from '../../../shared/models/custom-field.model';
import { ComponentTypes } from './enums/component-types.enum';
import { getCustomeFieldTypeId } from '../pipes/custom-field-type-id.pipe';
import { DesignerSectionItem } from './designer-section.model';

export interface LabelDetailsModel {
  labelId: number;
  tags: string;
  code: string;
  brandId: number;
  accountId: number;
  name: string;
  tenantTypeId: number;
  fileName: string;
  description: string;
  clicked: boolean;
  layout: LabelLayout;
  icon: string;
  height: number;
  width: number;
  createdByName: string;
  createdAt: Date;
  createdAtString: string;
  lastUpdatedByName: string;
  lastUpdatedAt: Date;
  lastUpdatedAtString: string;
  contentJson: string;
  imageContent: string;
  status: LabelStatus;
  allergenId: number;
  imageURL: SafeUrl;
  mediaUrl: string;
  filePath: string;
  contentXML: string;
  isEditable: boolean;
  isLandscape: boolean;
  isDayOfWeek: boolean;
  isTemplate?: boolean;
  isContinuous?: boolean;
  createdAtLocation: boolean;
  labelSizeId: number;
  printMarginRatio: number;
  displayHeight: number;
  displayWidth: number;
  outdatedWidget?: OutdatedWidget;
  printAddonsOnSeparateLabel?: boolean;
  publishAt?: Date;
  publishAtExpired?: boolean;
  syncStatusId?: number;
  canPublish?: boolean;
  isSubscription?: boolean;
  inUse?: boolean;
  isAvailableForRemotePrinting?: boolean;
  autoRemoveAdditionalWhiteSpace?: boolean;
  remotePrintingOutdated?: boolean;
  commissionRFID?: boolean;
  isRFID?: boolean;
  isDisabledForSubscriber?: boolean;
  sortOrder?: number;
  hasInvalidNFProfile?: boolean;
  rfidSettings?: RfidSettings;
  hasCustomCode?: boolean;
  isDynamicDOW?: boolean;
  key?: string;
}

export interface OutdatedWidget {
  widgetId: number;
  widgetName: string;
  widgetContentLastUpdatedAt: Date;
}

export interface LabelLayout {
  layoutId: number;
  layoutName: string;
}

export interface LabelStatus {
  statusId: number;
  name: string;
  description: string;
}

export class BlankCanvasTemplate {
  constructor(
    public width: number,
    public height: number,
    public description: string,
    public contentXML: string,
    public isDayOfWeek: boolean,
    public isContinuous: boolean,
    public commissionRFID?: boolean,
    public rfidSettings?: RfidSettings
  ) {}
}

export interface LabelUsage {
  products: LabelUsedInProduct[];
  ingredients: LabelUsedInIngredient[];
  timers: LabelUsedInTimer[];
  hasRemotePrinting: boolean;
  conflictingSubscriberAccounts: string[];
  isAvailableForInventory: boolean;
}

interface LabelUsedInProduct {
  menuItemId: number;
  name: string;
}

interface LabelUsedInIngredient {
  menuItemId: number;
  name: string;
}

interface LabelUsedInTimer {
  timerId: number;
  name: string;
}

export interface LabelProperty {
  isContinuous: boolean;
  commissionRFID: boolean;
  height: number;
  width: number;
  displayHeight: number;
  displayWidth: number;
  autoRemoveAdditionalWhiteSpace: boolean;
  isLandscape: boolean;
  labelSizeId: number;
  rfidSettings?: RfidSettings;
  isDesignerUOMChange?: boolean;
  isContinuousPropInvalid?: boolean;
}

export interface LabelPropertyOptions {
  isContinuous: boolean;
  labelHeight: number;
  labelWidth: number;
  labelDisplayHeight: number;
  labelDisplayWidth: number;
  labelSizeId: number;
  isContinuousPropInvalid: boolean;
  isContinuousNeedToChange: boolean;
  isLabelSizeChanged: boolean;
  autoRemoveAdditionalWhiteSpace: boolean;
  commissionRFID: boolean;
  labelSizeMetaData: any;
  rfidSettings?: RfidSettings;
  isDesignerUOMChange?: boolean;
}
export interface RfidSettings {
  generateEPC: boolean;
  encodingType: string;
  serialNumber: string;
  filter: number;
  partition: {
    selector: any;
    value: number;
  };
  companyPrefix: {
    selectedField: string;
    field: string;
    fieldFormat: string;
    format?: string;
    value: number;
    referralId?: string;
  };
  atmaFieldMap?: atmaField[];
  optionalFields?: any[];
  dateField: DateField;
  serialNumberField: SerialNumberField;
}

export interface DateField {
  type: string;
  option?: string;
  style?: string;
  epcIdentifier: string;
  format: string;
  date_additional_format: string;
  discardDate: {
    type: string;
  };
}

export interface SerialNumberField {
  encodingIndicator: number;
  lengthIndicator: number;
  serialNumberSource: string;
}
export interface atmaFieldMapItem {
  objectFieldType: string;
  atmaField: string;
  shelfLife: string;
}
export interface atmaField {
  objectField: allLabelItems;
  atmaField: string;
}
export interface MainlabelItem {
  type: string;
  referralId?: string;
}
export type allLabelItems = DiscardDateItem | CustomFieldItem | NutritionalFactITem | MainlabelItem;
export interface DiscardDateItem extends MainlabelItem {
  date_additional_format: string;
  discardDate: {
    type: string;
  };
}

export interface CustomFieldItem extends MainlabelItem {
  format: string;
  customFieldType: CustomFieldTypeEnum;
  date_additional_format?: string;
}

export interface DateItem extends MainlabelItem {
  type: string;
  style: string;
  format: string;
  option: string;
}

export interface NutritionalFactITem extends MainlabelItem {
  properties: {
    key: string;
    iteration: string;
  };
}

export class atmaField implements atmaField {
  constructor(dataItem: atmaFieldMapItem) {
    let data: any = { objectField: {} };
    const mapData: atmaFieldMapItem = { ...dataItem };
    data.atmaField = mapData.atmaField;
    if (mapData.objectFieldType.includes(',')) {
      const splittedData = mapData.objectFieldType.split(',');
      data.objectField.type = splittedData[0];
      data.objectField.referralId = splittedData[1];
    } else {
      if (mapData.objectFieldType.includes('$')) {
        const splittedData = mapData.objectFieldType.split('$');
        data.objectField.type = splittedData[0];
      } else {
        data.objectField.type = mapData.objectFieldType;
      }
    }
    Object.assign(this, data);
  }
}
export class DiscardDateItem implements DiscardDateItem {
  constructor(atmaField: atmaField, dataItem: atmaFieldMapItem) {
    const aditionalProperties = { date_additional_format: 'yyyy-MM-dd', discardDate: { type: dataItem.shelfLife } };
    const data: DiscardDateItem = { ...atmaField.objectField, ...aditionalProperties };
    Object.assign(this, data);
  }
}

export class CustomFieldItem implements CustomFieldItem {
  constructor(atmaField: atmaField, dataItem: atmaFieldMapItem, custome_fields: DesignerSectionItem[]) {
    const splittedData = dataItem.objectFieldType.split('$');
    const customFieldTypeId = custome_fields.find((x) => x.map_value === dataItem.objectFieldType)?.typeId;
    const aditionalProperties = { date_additional_format: customFieldTypeId === CustomFieldTypeEnum.Int ? 'yyyy-MM-dd' : null, format: splittedData[1], customFieldType: customFieldTypeId };
    const data: CustomFieldItem = { ...atmaField.objectField, ...aditionalProperties };
    Object.assign(this, data);
  }
}

export class NutritionalFactITem implements NutritionalFactITem {
  constructor(atmaField: atmaField, dataItem: atmaFieldMapItem) {
    const aditionalProperties = { properties: { key: '747ef598-71ca-4de1-b735-d938e83d1c6f'.toUpperCase(), iteration: 'value1' } };
    const data: NutritionalFactITem = { ...atmaField.objectField, ...aditionalProperties };
    data.type = ComponentTypes.WIDGET_NF;
    Object.assign(this, data);
  }
}

export class DateItem implements DateItem {
  constructor(atmaField: atmaField) {
    const aditionalProperties = { format: 'yyyy-MM-dd', style: 'date', type: 'date', option: 'now' };
    const data: DateItem = { ...atmaField.objectField, ...aditionalProperties };
    Object.assign(this, data);
  }
}
