<div>
    <h1 class="mat-dialog-title dialog-title">
      {{ 'login_page.login_warning_message_title' | translate }}
    </h1>

    <div [innerHtml]="'login_page.login_warning_message' | translate"></div>
    <!-- <p class="c-grey">{{ 'login_page.login_warning_message' | translate }}</p> -->
</div>

<div class="buttons-wrapper mt-4">
    <button mat-flat-button color="accent" (click)="close()" type="button" class="login-button btn btn-def btn-block" cdkFocusInitial>{{ 'ok' | translate }}</button>
</div>