import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[nomeInfiniteScroll]'
})
export class InfiniteScrollDirective {
  @Output() bottomReached = new EventEmitter<void>();
  triggered = false;

  constructor(private hostElement: ElementRef) { }

  @HostListener('scroll')
  onHostScroll(): void {
    const totalHeight = this.hostElement.nativeElement.scrollHeight;
    const elementHeight = this.hostElement.nativeElement.offsetHeight;
    const howMuchScrolled = this.hostElement.nativeElement.scrollTop;
    const bottom = totalHeight - elementHeight - howMuchScrolled;
    if (this.triggered && bottom > 1) {
      this.triggered = false;
    }
    // checking for 1px instead of 0px since while zooming in/out the browser someimes has a 1 px margin of error calculation 
    if (bottom <= 1 && !this.triggered) {
      this.triggered = true;
      this.bottomReached.emit();
    }
  }
}
