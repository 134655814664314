import { AfterContentInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild, OnInit, ComponentFactoryResolver, ViewContainerRef, HostListener } from '@angular/core';
import { Subscription, Observable, forkJoin, of } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent, NavigationStart, Params } from '@angular/router';
import { environment } from '../../../environments/environment';
import { MenuService } from '../../modules/menu-manager/services/menu.service';
import { BrandService } from '../../shared/services/brand.service';
import { NavigationService } from '../../shared/services/navigation.service';
import { NomeBreadcrumbService } from '../../shared/services/nome-custom-breadcrumb.service';
import { TreeService } from '../../shared/services/tree.service';
import { RoleService } from '../services/role.service';
import { BrandModel } from './../../shared/models/brand-model';
import { TreeTypeEnum } from './../../shared/models/enums/TreeTypes.enum';
import { URLPath } from './../../shared/models/enums/url-paths.enum';
import { IBreadcrumb } from './../../shared/models/IBreadcrumb.model';
import { Section, SidenavSection } from './../../shared/models/sidenavSection.model';
import { TreeCmp, TreeNode } from './../../shared/models/Tree-Node.model';
import { AuthService } from './../auth/auth.service';
import { SnackbarService } from './../../shared/services/snackbar.service';
import { AccountService } from './../../shared/services/account.service';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { MatSidenav } from '@angular/material/sidenav';
import { Modules } from './../../shared/models/enums/Modules.enum';
import { Permissions } from './../../shared/models/enums/Permissions.enum';
import { HelpService } from './../../shared/services/help.service';
import { deepClone, defined, encodeSpecialCharacters, escapeForwardSlash } from './../../shared/helpers/app.helpers';
import { LocalizationService } from './../../shared/services/localization/localization.service';
import { ThemeService } from './../../shared/services/theme.service';
import { NamedIdentity } from '../../shared/models/named-identity.model';
import { LocationManagerService } from '../../modules/location-manager/services/location-manager.service';
import { TreeComponent } from '../../shared/components/tree/tree.component';
import { TreeSearchEvent } from '../../shared/models/tree-search-event.model';
import { LocationService } from '../../shared/services/location.service';
import { TableService } from '../../shared/services/table.service';
import { MainService } from '../../shared/services/main.service';
import { LocalStorageFlags } from '../../shared/models/enums/local-storage-flags.enum';
import { ResellerCheckService } from '../../shared/services/reseller-check.service';
import { KnowledgeBaseService } from '../../shared/services/knowledge-base.service';
import { DynamicComponentModel, IDynamicComponent } from '../../shared/models/dynamic-component-model.model';
import { LoginService } from '../services/login.service';
import { HelpAndSupport } from '../../shared/models/help-and-support.enum';
import { DialogCobrowseService } from '../../shared/services/dialog-cobrowse.service';
import { ConfirmationModalData } from '../../shared/models/confirmation-dialog.model';
import { ConfirmationComponent } from '../../shared/components/confirmation/confirmation.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { P } from '@angular/cdk/keycodes';
import { NodeTypeEnum } from '../../shared/models/enums/node-type.enum';
import { SignalRService } from '../services/signal-r.service';
@Component({
  selector: 'nome-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements AfterContentInit, OnInit, OnDestroy {
  @ViewChild('rightSideNavigator', { read: ViewContainerRef, static: true }) rightSideNavigatorContainerReference: ViewContainerRef;
  @ViewChild('sideNav', { static: true }) sideNav: ElementRef<HTMLDivElement>;
  isDrawerOpen = true;
  LocationToggled = false;
  AccountManagementToggled = false;
  treeTitle: string = '';
  assets: string = environment.assets;
  brandImagesAssets: string;
  selectedNode;
  accountId: number;
  brandId: number;
  locationId: number;
  treeComponent: TreeCmp;
  detectBrandChange: Subscription;
  currentUser;
  modules = Modules;
  permissions = Permissions;
  hasViewAccountInfoModulePermission = false;
  brandsList: BrandModel[];
  currentBrand: BrandModel;
  dataTreeItems: TreeNode[] = [];
  visible: Boolean = false;
  sections: SidenavSection[];
  isReseller: boolean;
  disableFirstBreadcrumb: boolean;
  isPopulatedInMenuItem: Boolean = false;
  punchoutSession: Boolean = false;
  MenuItemsTreeNode: Subscription;
  selectedTreeNode;
  rightSideNavigatorOpened: boolean;
  isImpersonatingAccount: boolean = false;
  isImpersonatingResellerAccount: boolean = false;
  impersonationModeData: any;
  resellerImpersonationModeData: any;
  detectImpersonationModeChange: Subscription;
  detectReloadPage: Subscription;
  treeSidenavMode: string = 'over';
  mainSidenavMode: string = 'side';
  treeTypeEnum = TreeTypeEnum;
  currentTreeType: number;
  detectPageReadyChange: Subscription;
  treeSubtitle: string;
  isZYPortal: boolean;
  knowledgeBaseMenuOptionVisible: boolean;
  private kbTokenChangedSubscription: Subscription;
  private rightSideNavigatorListener: Subscription;
  private rightSideCloseSubscription: Subscription;
  linkedAccounts: any[];

  categoryId: number;
  menuId: number;

  @ViewChild('mainTreeSidenav', { static: true }) mainTreeSidenav: MatSidenav;
  @ViewChild(TreeComponent, { static: true }) tree: TreeComponent;
  @ViewChild('mainSidenav', { static: true }) mainSidenav: MatSidenav;

  get treeSidenavCloseDisabled(): boolean {
    return this.treeSidenavMode === 'side';
  }

  private fetchLocationsAllowed = true;
  private routerEventsSubscription: Subscription;

  get lazyLoadTheTree(): boolean {
    return this.locationTree || this.menuItemTree || this.menuItemCategoryTree;
  }

  private get locationTree(): boolean {
    return this.currentTreeType === this.treeTypeEnum.Location;
  }

  private get menuItemTree(): boolean {
    return this.currentTreeType === this.treeTypeEnum.ProductCategory || this.currentTreeType === this.treeTypeEnum.IngredientCategory;
  }

  private get menuItemCategoryTree(): boolean {
    return this.currentTreeType === this.treeTypeEnum.MenuItemCategory;
  }

  externalWindow: any;

  private mainSideNavOptions = {
    openDelayPeriod: 300,
    openTimeoutSubscription: null
  };
  resizingEvent = {
    isResizing: false,
    startingCursorX: 0,
    startingWidth: 0
  };

  constructor(
    private router: Router,
    public treeService: TreeService,
    public brandService: BrandService,
    public menuService: MenuService,
    public roleService: RoleService,
    private navigationService: NavigationService,
    private nomeCustomBreadCrumbsService: NomeBreadcrumbService,
    public authService: AuthService,
    private changeDetect: ChangeDetectorRef,
    private route: ActivatedRoute,
    private snackbarService: SnackbarService,
    private accountService: AccountService,
    private helpService: HelpService,
    private breakpointObserver: BreakpointObserver,
    private themeService: ThemeService,
    public localizationService: LocalizationService,
    private locationManagerService: LocationManagerService,
    private locationService: LocationService,
    private tableService: TableService,
    private mainService: MainService,
    private resellerCheckService: ResellerCheckService,
    private knowledgeBaseService: KnowledgeBaseService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private loginService: LoginService,
    private dialogCobrowseService: DialogCobrowseService,
    private dialog: MatDialog,
    private notificationService: SignalRService
  ) {
    this.brandImagesAssets = this.themeService.getbrandImagesAssets();

    if (this.router.url === '/') {
      this.router.navigate(['dashboard']);
    }
    this.punchoutSession = localStorage.getItem(LocalStorageFlags.punchoutSession) === 'True';
    this.isReseller = this.authService.getIsReseller();
    this.roleService.clearUserRoles();
    this.sections = [] = this.roleService.getAllowedSections();
    this.accountId = this.authService.getCurrentUserAccountId();
    this.brandId = this.brandService.getBrandStorage();
    this.setKnowledgeBaseMenuOptionVisible();
    this.getKnowledgeBaseTokenForNonResellersIfEmptyAndAllowed();

    this.hasViewAccountInfoModulePermission = this.authService.hasPermission(this.modules.ACCOUNT_INFO, this.permissions.VIEW);
    this.impersonationModeData = this.authService.getImpersonationMode();
    this.isImpersonatingAccount = this.impersonationModeData !== null;

    this.resellerImpersonationModeData = this.authService.getResellerImpersonationMode();
    this.isImpersonatingResellerAccount = this.resellerImpersonationModeData !== null;
    if (this.isImpersonatingResellerAccount) {
      this.roleService.clearUserRoles();
      this.roleService.setUserAuthorizedModules(this.resellerImpersonationModeData.modules);
      this.sections = [] = this.roleService.getAllowedSections();
    }
    if (!this.knowledgeBaseMenuOptionVisible) {
      this.removeSupportSection();
    }
    this.kbTokenChangedSubscription = this.authService.kbTokenChanged.subscribe(() => {
      this.setKnowledgeBaseMenuOptionVisible();
    });
    this.notificationService.initiateSignalrConnection(this.accountId);

    // get tree categories...
    this.detectBrandChange = this.brandService.currentBrandSubject.subscribe((brand) => {
      this.authService.setSelectedBrand(brand);
      const routerUrl = this.router.url;
      const oldBrand = JSON.parse(JSON.stringify(this.brandId));
      if (brand && brand.brandId !== oldBrand) {
        this.brandId = brand.brandId;
        if (routerUrl.includes(URLPath.MENU_ITEMS) && !routerUrl.includes(URLPath.MENUS)) {
          let locationId = this.route.snapshot.queryParams['locationId'];
          this.getItemsTree(TreeTypeEnum.ProductCategory, null, null, locationId);
        } else if (routerUrl.includes(URLPath.INGREDIENTS) && !routerUrl.includes(URLPath.MENUS)) {
          let locationId = this.route.snapshot.queryParams['locationId'];
          this.getItemsTree(TreeTypeEnum.IngredientCategory, null, null, locationId);
        } else if (routerUrl.includes(URLPath.MEDIA)) {
          this.getItemsTree(TreeTypeEnum.MediaCategory);
        }
      }
    });

    this.detectImpersonationModeChange = this.authService.impersonationMode.subscribe((mode) => {
      this.accountId = this.authService.getCurrentUserAccountId();
      this.roleService.clearUserRoles();
      this.setKnowledgeBaseMenuOptionVisible();
      this.getBrands();
      this.notificationService.initiateSignalrConnection(this.accountId);

      if (mode) {
        this.impersonationModeData = this.authService.getImpersonationMode();
        this.resellerImpersonationModeData = this.authService.getResellerImpersonationMode();

        if (this.impersonationModeData) {
          this.isImpersonatingAccount = true;
        } else if (this.resellerImpersonationModeData) {
          this.roleService.setUserAuthorizedModules(this.resellerImpersonationModeData.modules);
          this.isImpersonatingResellerAccount = true;
        }
      } else {
        this.roleService.refreshUserAllowedModules();
        this.impersonationModeData = null;
        this.resellerImpersonationModeData = null;

        this.isImpersonatingResellerAccount = false;
        this.isImpersonatingAccount = false;
      }

      this.sections = [] = this.roleService.getAllowedSections();
      if (!this.knowledgeBaseMenuOptionVisible) {
        this.removeSupportSection();
      }

      if (this.isImpersonatingAccount === false && this.isImpersonatingResellerAccount === false) {
        this.snackbarService.openSnackBar('account_manager.accounts.return-to-resseller-view');
      }

      this.router.navigate(['/dashboard']);
    });

    this.detectReloadPage = this.authService.reloadPage.subscribe((status) => {
      this.accountId = this.authService.getCurrentUserAccountId();
      this.roleService.clearUserRoles();
      this.sections = [] = this.roleService.getAllowedSections();
      this.snackbarService.openSnackBar(`account_manager.accounts.configure-account-successful`, undefined, undefined, undefined, [
        {
          old_value: '{accountName}',
          new_value: this.authService.getTenantName()
        }
      ]);
      this.router.navigate(['/dashboard']);
    });

    this.routerEventsSubscription = this.router.events.subscribe((evt: any) => {
      switch (true) {
        case evt instanceof NavigationStart:
          // Pressing browser back button right after entering impersonation mode
          if (this.isImpersonatingAccount && evt.url === '/accountmanager/accounts') {
            this.router.navigate(['/dashboard']);
          }
          break;
        case evt instanceof NavigationEnd:
          this.decideWhichTreeToLoadByUrl(evt.url);
          this.categoryId = this.currentTreeType === this.treeTypeEnum.MenuItemCategory ? parseInt(evt.url.split('/')[7], 10) : parseInt(evt.url.split('/')[4], 10);
          this.menuId = this.currentTreeType === this.treeTypeEnum.MenuItemCategory ? parseInt(evt.url.split('/')[4], 10) : null;
          this.fetchLocationsAllowed = true;
          this.closeDrawer();
          if (this.detectPageReadyChange) {
            this.detectPageReadyChange.unsubscribe();
          }
          this.detectPageReadyChange = this.helpService.pageReady.subscribe((ready) => {
            if (ready) {
              this.helpService.resume();
            }
          });
      }
    });
    this.currentUser = this.authService.getCurrentUser();

    //Set Secondary Language Configs
    this.getAccountAndBrands();
    this.updateRightSideNavigatorContent();
    this.listenToRightSideCloseSubscription();

    this.linkedAccounts = this.authService.getLinkedAccounts();
  }

  private getAccountAndBrands(): void {
    forkJoin([this.accountService.getAccountConfig(this.accountId), this.brandService.getBrands(this.accountId)]).subscribe(([accountInfo, brands]) => {
      this.accountService.setSecondaryLanguageConfigAndGetPortalBaseUrl(accountInfo);
      this.onGetBrands(brands);
    });
  }

  private listenToRightSideCloseSubscription(): void {
    this.rightSideCloseSubscription = this.mainService.onCloseRightSide().subscribe(() => {
      this.rightSideNavigatorOpened = false;
      this.rightSideNavigatorContainerReference.clear();
    });
  }

  isSmall$: Observable<any> = this.breakpointObserver.observe([Breakpoints.HandsetPortrait, Breakpoints.TabletPortrait, Breakpoints.Small, Breakpoints.XSmall]).pipe(map((result) => result.matches));

  isPortableDevice$: Observable<any> = this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet]).pipe(map((result) => result.matches));

  ngOnInit(): void {
    this.isSmall$.subscribe((matches) => {
      if (matches) this.treeSidenavMode = 'over';
      else {
        this.treeSidenavMode = 'side';
        this.mainTreeSidenav.open();
      }
    });

    this.isPortableDevice$.subscribe((matches) => {
      if (matches) {
        this.mainSidenavMode = 'over';
        this.closeDrawer();
      } else this.mainSidenavMode = 'side';
    });
    this.helpService.init();
  }

  //TODO: enhancement pass [disableClose]="true"  mode="over" as customizable settings depends on the component being rendered
  private updateRightSideNavigatorContent(): void {
    this.rightSideNavigatorListener = this.mainService.onRightSideNavigatorContentChanged().subscribe((component: DynamicComponentModel) => {
      this.rightSideNavigatorOpened = true;
      this.changeDetect.detectChanges();
      this.rightSideNavigatorContainerReference.clear();
      const componentReference = this.rightSideNavigatorContainerReference.createComponent(component.content);
      // const componentReference = this.rightSideNavigatorContainerReference.createComponent<IDynamicComponent>(componentFactory)
      if (defined(component.data)) {
        Object.entries(component.data).forEach((item) => {
          componentReference.instance[item[0]] = item[1];
        });
      }
    });
  }

  onSectionOpen(section: SidenavSection): void {
    this.closeSections();
    this.openSection(section);
  }

  private closeSections(): void {
    this.sections.forEach((section) => this.closeSection(section));
  }

  closeSection(section: SidenavSection): void {
    section.isToggled = false;
  }

  private openSection(section: SidenavSection): void {
    section.isToggled = true;
  }

  sectionActive(url: string): boolean {
    const currentModuleRouterUrl = this.router.url.split('/')[1];
    const currentModuleSectionMainRoute = url?.split('/')[1];
    if (currentModuleRouterUrl === currentModuleSectionMainRoute) {
      return true;
    }
    return false;
  }

  setKnowledgeBaseMenuOptionVisible(): void {
    this.knowledgeBaseMenuOptionVisible = this.resellerCheckService.isZYReseller() && this.authService.getKnowledgeBaseFlag() && !this.authService.getIsReseller() && this.authService.hasKnowledgeBaseToken();
  }

  private removeSupportSection(): void {
    // Added to filter sections and remove help and support section in case of this.knowledgeBaseMenuOptionVisible = false
    this.sections = this.sections.filter((sec: SidenavSection) => !sec.mainSection.notIncludedInDashboard);
  }

  private getKnowledgeBaseTokenForNonResellersIfEmptyAndAllowed(): void {
    if (this.authService.getKnowledgeBaseFlag() && !this.authService.getIsReseller() && !this.authService.hasKnowledgeBaseToken()) {
      this.knowledgeBaseService.configureKnowledgeBaseTokenForZYPortal(this.accountId, this.authService.getCurrentUserEmail());
    }
  }

  sectionTrackFn(section: SidenavSection) {
    if (section && section.mainSection) {
      return section.mainSection.title;
    }
  }

  subsectionTrackFn(subSection: any) {
    if (subSection) {
      return subSection.title;
    }
  }

  help() {
    this.helpService.resume(true);
  }

  getBrands() {
    this.brandService.getBrands(this.accountId).subscribe((response) => this.onGetBrands(response));
  }

  private onGetBrands(brands: BrandModel[]): void {
    this.brandsList = brands;

    if (this.isImpersonatingAccount) {
      this.currentBrand = this.brandsList[0];
    }

    if (this.brandId) {
      this.currentBrand = this.brandsList.find((brand) => brand.brandId === this.brandId);
      if (!this.currentBrand) {
        this.currentBrand = this.brandsList[0];
      }
    } else {
      this.currentBrand = this.brandsList[0];
    }
    this.brandService.setCurrentBrand(this.currentBrand);
    if (this.brandsList && this.brandsList.length !== 0) {
      this.brandId = this.brandService.getBrandStorage() ? this.brandService.getBrandStorage() : this.brandsList[0].brandId;
    }
    this.authService.addOrRemoveTheSupportChat();
  }

  exitImpersonationMode() {
    this.router.navigate(['/dashboard'], { queryParams: { exitImpersonation: 1 } });
  }

  exitResellerImpersonationMode() {
    this.router.navigate(['/dashboard'], { queryParams: { exitImpersonation: 2 } });
  }

  reloadPage(userId: number, accountId: number) {
    this.router.navigate(['/dashboard'], { queryParams: { userId: userId, accountId: accountId } });
  }

  updateTreeData(data: TreeNode[]) {
    this.dataTreeItems = [];
    this.dataTreeItems = data;
    this.mainService.emitTreeItemsChangeEvent(data);

    if (!this.changeDetect) {
      this.changeDetect.detectChanges();
    }
  }

  getItemsTree(treeType: number, treeNodeId?: number, menuId?: number, locationId?: number, enableFilter?: boolean) {
    // case of first load
    this.tree.clearSearchField();
    if (!treeNodeId) {
      this.treeService.getRootNode(this.accountId, treeType, this.brandId, menuId, locationId, locationId ? enableFilter : false).subscribe((result) => {
        if (treeType === TreeTypeEnum.IngredientCategory) {
          this.loopOverIngredientCategoryNodes(result.tree);
        }

        const atLeastOneNodeInTheTreeExists = result && result.tree && result.tree.length > 0;

        if (treeType === TreeTypeEnum.Location) {
          if (atLeastOneNodeInTheTreeExists) {
            const firstNode = result.tree[0];
            firstNode.selected = true;
            firstNode.expanded = true;
            firstNode.loading = true;
            this.navigateToLocationWithoutFetchingLocations(firstNode);
            this.getLocationNodeChildren(firstNode);
          }
        }

        if (treeType === TreeTypeEnum.MenuItemCategory) {
          if (atLeastOneNodeInTheTreeExists) {
            const firstNode = result.tree[0];
            firstNode.selected = true;
            firstNode.expanded = true;
            firstNode.highlightOnLoad = true;
          }
        }

        this.updateTreeData(result.tree);
      });
    } else {
      // case of refresh
      forkJoin([
        this.treeService.getTreeByNodeId(treeNodeId, this.accountId, this.brandId, treeType, menuId, locationId, enableFilter),
        this.locationTree ? this.locationService.getNodeChildren(this.accountId, treeNodeId, true) : of([])
      ]).subscribe(([result, locationNodeChildren]) => {
        if (treeType === TreeTypeEnum.IngredientCategory) {
          this.loopOverIngredientCategoryNodes(result.tree);
        }

        if (treeType === TreeTypeEnum.Location) {
          this.treeService.setNodeChildren(result.tree, treeNodeId, locationNodeChildren);
          this.emitTreeReadyEvent(result.tree);
        }

        this.updateTreeData(result.tree);
      });
    }
  }

  getNodeChildren(node: TreeNode): void {
    if (this.locationTree) {
      this.getLocationNodeChildren(node);
    }
  }

  searchInTree(event: TreeSearchEvent): void {
    if (this.currentTreeType === TreeTypeEnum.Location) {
      this.locationService.getLocationTreeNodes(this.accountId, event.id, this.brandId, event.text, this.tree, (response) => {
        this.updateTreeData(response.tree);
        this.emitTreeReadyEvent(response.tree);
      });
    } else if (this.currentTreeType === TreeTypeEnum.ProductCategory || this.currentTreeType === TreeTypeEnum.IngredientCategory || this.currentTreeType === TreeTypeEnum.MenuItemCategory) {
      this.filterCategoryTree(this.currentTreeType, event.text);
    }
  }

  filterCategoryTree(treeType: number, searchKeyword: string) {
    let locationId = this.route.snapshot.queryParams['locationId'];
    if (this.categoryId) {
      this.treeService.getTreeByNodeId(this.categoryId, this.accountId, this.brandId, treeType, this.menuId, locationId, false, searchKeyword).subscribe((response) => {
        this.updateTreeData(response.tree);
        this.emitTreeReadyEvent(response.tree);
      });
    } else {
      this.treeService.getRootNode(this.accountId, treeType, this.brandId, null, locationId, null, null, searchKeyword).subscribe((response) => {
        this.updateTreeData(response.tree);
        this.emitTreeReadyEvent(response.tree);
      });
    }
  }

  decideWhichTreeToLoadByUrl(url: string) {
    this.isPopulatedInMenuItem = false;
    let locationId = this.route.snapshot.queryParams['locationId'];
    let categoryId = null;
    categoryId = parseInt(url.split('/')[4], 10);

    // Menu Items case
    if (url.includes(URLPath.MENU_ITEMS) && !url.includes(URLPath.MENUS)) {
      this.isPopulatedInMenuItem = true;
      this.treeComponent = new TreeCmp(TreeTypeEnum.ProductCategory, this.accountId, this.brandId, false, true);
      this.getItemsTree(this.treeComponent.type, categoryId, null, locationId);
      this.currentTreeType = TreeTypeEnum.ProductCategory;
      return;
    }
    // menu items of a menu tree
    else if (url.includes(URLPath.MENU_ITEMS) && url.includes(URLPath.MENUS)) {
      categoryId = parseInt(url.split('/')[7], 10);
      const menuId = parseInt(url.split('/')[4], 10);
      this.treeComponent = new TreeCmp(TreeTypeEnum.MenuItemCategory, this.accountId, this.brandId, false, true);
      this.getItemsTree(this.treeComponent.type, categoryId, menuId, locationId, locationId ? true : false);
      this.currentTreeType = TreeTypeEnum.MenuItemCategory;
      return;
    }
    // ingredient tree
    else if (url.includes(URLPath.INGREDIENTS) && !url.includes(URLPath.MENUS)) {
      this.isPopulatedInMenuItem = true;
      this.treeComponent = new TreeCmp(TreeTypeEnum.IngredientCategory, this.accountId, this.brandId, false, true);
      let locationId = this.route.snapshot.queryParams['locationId'];
      this.getItemsTree(this.treeComponent.type, categoryId, undefined, locationId);
      this.currentTreeType = TreeTypeEnum.IngredientCategory;
      return;
    }
    // location tree
    else if (url.includes(URLPath.LOCATIONS_MANAGER)) {
      if (url.includes(URLPath.ALL_TERMINALS)) {
        this.updateTreeData([]);
      } else {
        this.currentTreeType = TreeTypeEnum.Location;
        this.treeComponent = new TreeCmp(TreeTypeEnum.Location, this.accountId, this.brandId, false, true);
        const locId = parseInt(url.split('/')[3], 10);
        const locationId = !isNaN(locId) ? locId : null;
        if (this.fetchLocationsAllowed) {
          this.getItemsTree(this.treeComponent.type, locationId);
        }
      }
    }
    // media category tree
    else if (url.includes(URLPath.MEDIA)) {
      this.treeComponent = new TreeCmp(TreeTypeEnum.MediaCategory, this.accountId, this.brandId, false, true);
      this.currentTreeType = TreeTypeEnum.MediaCategory;
      this.getItemsTree(this.treeComponent.type, categoryId, null, locationId);

      return;
    } else {
      this.treeComponent = new TreeCmp(null, null, null, false, true);
      this.updateTreeData([]);
    }
  }

  ngAfterContentInit(): void {
    this.treeService.treeTitleSubject.subscribe((title) => {
      this.treeTitle = title;
    });

    this.treeService.treeSubtitleSubject.subscribe((subtitle) => {
      this.treeSubtitle = subtitle;
    });

    this.treeService.treeVisibleSubject.subscribe((visible) => {
      this.visible = visible;
    });
    this.treeService.treeItemsSubject.subscribe((treeItems) => {
      this.dataTreeItems = treeItems;
    });
    this.authService.currentUserNameChanged.subscribe((currentUserName) => {
      if (currentUserName) {
        this.currentUser = currentUserName;
      }
    });

    // this.MenuItemsTreeNode = this.treeService.selectedNodeId.subscribe(nodeId => {
    //   if (nodeId) {
    //     this.treeNodeSelected(nodeId);
    //   }
    // });
  }

  openDrawerAfterADelay(): void {
    this.mainSideNavOptions.openTimeoutSubscription = setTimeout(this.openDrawer.bind(this), this.mainSideNavOptions.openDelayPeriod);
  }

  openDrawer() {
    if (this.mainSidenavMode == 'over') {
      this.mainSidenav.open();
    }
    const sidenavContentClassList = this.sideNav.nativeElement.classList;
    sidenavContentClassList.value = sidenavContentClassList.value + ' drawer-open';
    this.isDrawerOpen = true;
  }

  private clearTheMainSideNavOpenTimeoutSubscription(): void {
    clearTimeout(this.mainSideNavOptions.openTimeoutSubscription);
  }

  closeDrawer() {
    this.clearTheMainSideNavOpenTimeoutSubscription();
    if (this.mainSidenavMode == 'over') {
      this.mainSidenav.close();
    }
    const sidenavContentClassList = this.sideNav.nativeElement.classList;
    sidenavContentClassList.remove('drawer-open');
    this.isDrawerOpen = false;
    this.closeSections();
    this.LocationToggled = false;
    this.AccountManagementToggled = false;
  }

  treeNodeSelected(node: TreeNode) {
    this.treeService.emitSelectedNodeChangedEvent(node);

    this.selectedNode = node;
    let storedItemsBreadCrumbs: IBreadcrumb[] = [];
    let queryParams: Params;
    storedItemsBreadCrumbs = this.nomeCustomBreadCrumbsService.getstoredBreadCrumbs();
    //If nodeID was not already loaded in the breadcrumb,then we will need to populate it from the db.
    //This can be optimized later by building the breaedcrumb from the tree nodes.
    if (!storedItemsBreadCrumbs.find((x) => x.url.search('/' + node.id + '/') >= 0)) {
      this.nomeCustomBreadCrumbsService.setStoredBreadCrumbs([]);
    }
    switch (this.treeComponent.type) {
      case TreeTypeEnum.ProductCategory:
        // this.navigationService.navigateToUrl(`menumanager/menuitems/catdetail/${node.id}/${node.name}`);
        this.router.navigate([`menumanager/menuitems/catdetail/${node.id}/${escapeForwardSlash(node.name)}`], { queryParams: { ...this.navigationService.getQueryParams(), startingItem: 0 } });
        break;
      case TreeTypeEnum.IngredientCategory:
        // this.navigationService.navigateToUrl(`menumanager/ingredients/catdetail/${node.id}/${node.name}`);
        this.router.navigate([`menumanager/ingredients/catdetail/${node.id}/${escapeForwardSlash(node.name)}`], { queryParams: { ...this.navigationService.getQueryParams(), startingItem: 0 } });
        break;
      case TreeTypeEnum.MenuItemCategory:
        const menuId = parseInt(this.router.url.split('/')[4], 10);
        const menuName = decodeURI(this.router.url.split('/')[5]).split('?')[0];
        this.navigationService.navigateToUrl(`menumanager/menus/menuitems/${menuId}/${escapeForwardSlash(menuName)}/catdetail/${node.id}/${escapeForwardSlash(node.name)}`, {
          ...this.navigationService.getQueryParams(),
          startingItem: 0
        });
        break;
      case TreeTypeEnum.Location:
        // Add service call to validate if can navigate to location
        if (node.type == NodeTypeEnum.Store) {
          this.locationManagerService.validateMoveLocations(this.accountId, node.id).subscribe(
            (response) => {
              this.navigateToLocationWithoutFetchingLocations(node);
            },
            (err) => {
              const previousRoute = this.router.url;
              this.navigationService.setPreviousRoute(previousRoute);

              let dialogData = new ConfirmationModalData(
                'locations_manager.move_multiple_locations.unable_to_access_location.title',
                'locations_manager.move_multiple_locations.unable_to_access_location.message',
                'ok',
                null
              );
              const confirmationDlg = this.dialog.open(ConfirmationComponent, { data: dialogData });

              confirmationDlg.afterClosed().subscribe((ok) => {
                if (ok) {
                  this.navigationService.goBack();
                }
              });
            }
          );
        } else {
          this.navigateToLocationWithoutFetchingLocations(node);
        }

        break;
      case TreeTypeEnum.MediaCategory:
        this.navigationService.navigateToUrl(`mediamanager/media/catdetail/${node.id}/${escapeForwardSlash(node.name)}`, this.navigationService.getQueryParams());
        break;
    }
    if (this.treeSidenavMode == 'over') this.mainTreeSidenav.close();
  }

  updateApp() {
    this.authService.showUpdateAppDialog.next('force');
  }

  openMenu(e) {
    e.stopPropagation();
  }

  logout() {
    this.roleService.clearUserRoles();
    this.authService.logout();
    this.notificationService.removeSignalrConnection();
  }

  navigateToAccountInfoScreen() {
    this.navigationService.navigateToUrl(`accountmanager/account-info`);
  }

  navigateToProfileScreen() {
    this.navigationService.navigateToUrl(`profilemanager/profile`);
  }

  navigateToReleaseNotes() {
    if (this.authService.hasPermission(this.modules.RELEASE_NOTES, this.permissions.EDIT, true)) {
      this.navigationService.navigateToUrl(`releasemanager/release-notes`);
    } else {
      const url = this.router.serializeUrl(this.router.createUrlTree(['releasemanager/release-notes-template']));
      window.open(url, '_blank');
    }
  }

  private navigateToKnowledgeBase(): void {
    this.knowledgeBaseService.openKnowledgeBasePortalInNewTab(this.authService.getKnowledgeBaseToken());
  }

  onSubSectionClicked(subSection: Section): void {
    if (subSection.title === HelpAndSupport.HELP_CENTER) {
      this.navigateToKnowledgeBase();
    } else if (subSection.title === HelpAndSupport.SUPPORT_CODE) {
      this.dialogCobrowseService.showDialog(true);
    }
  }

  switchAccount(userId: number, accountId: number): void {
    this.loginService.switchAccount(userId, accountId).subscribe((response) => {
      this.loginService.getUserLinkedAccounts(userId).subscribe((next) => {
        this.authService.setLinkedAccounts(next);
        this.linkedAccounts = next;
        this.reloadPage(userId, accountId);
      });
    });
  }

  ngOnDestroy() {
    if (this.MenuItemsTreeNode) {
      this.MenuItemsTreeNode.unsubscribe();
    }
    if (this.kbTokenChangedSubscription) {
      this.kbTokenChangedSubscription.unsubscribe();
    }
    this.rightSideNavigatorListener?.unsubscribe();
    this.rightSideCloseSubscription?.unsubscribe();
    this.routerEventsSubscription.unsubscribe();
    this.detectReloadPage?.unsubscribe();
  }

  onRouterOutletDeactivate(): void {
    this.tree.clearSearchField();
  }

  private navigateToLocation(location: NamedIdentity): void {
    this.navigationService.navigateToUrl(`locationsmanager/locations/${location.id}/${encodeSpecialCharacters(location.name)}`);
  }

  private navigateToLocationWithoutFetchingLocations(location: NamedIdentity): void {
    this.fetchLocationsAllowed = false;
    this.navigateToLocation(location);
  }

  private loopOverIngredientCategoryNodes(tree: TreeNode[]): void {
    this.treeService.addSubscriptionIconToPublisherNodes(tree);
  }

  private emitTreeReadyEvent(tree: TreeNode[]): void {
    this.locationManagerService.emitTreeReadyEvent(deepClone(tree));
  }

  private getLocationNodeChildren(node: TreeNode): void {
    this.locationService.getLocationNodeChildren(this.accountId, node, this.tree, () => {
      this.emitTreeReadyEvent(this.tree.dataSource.data);
    });
  }
  // sidenav resizing actions
  startResizing(event: MouseEvent): void {
    this.resizingEvent = {
      isResizing: true,
      startingCursorX: event.clientX,
      startingWidth: this.navigationService.sidenavWidth
    };
  }
  @HostListener('window:mousemove', ['$event'])
  updateSidenavWidth(event: MouseEvent) {
    // No need to even continue if we're not resizing
    if (!this.resizingEvent.isResizing) {
      return;
    }

    // 1. Calculate how much mouse has moved on the x-axis
    const cursorDeltaX = event.clientX - this.resizingEvent.startingCursorX;

    // 2. Calculate the new width according to initial width and mouse movement
    const newWidth = this.resizingEvent.startingWidth + cursorDeltaX;

    // 3. Set the new width
    this.navigationService.setSidenavWidth(newWidth);
  }
  @HostListener('window:mouseup')
  stopResizing() {
    this.resizingEvent.isResizing = false;
  }
  @HostListener('window:resize')
  updateSidenavWidthOnWindowResize() {
    this.navigationService.sidenavMaxWidth = window.innerWidth / 2;
    if (this.navigationService.sidenavWidth > this.navigationService.sidenavMaxWidth) this.navigationService.setSidenavWidth(this.navigationService.sidenavMaxWidth);
  }
}
