import { Injectable, Type } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { deepClone } from '../helpers/app.helpers';
import { DynamicComponentModel } from '../models/dynamic-component-model.model';
import { TreeNode } from '../models/Tree-Node.model';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  private treeItemsSubject = new Subject<TreeNode[]>();
  private rightSideNavigatorContent = new Subject<DynamicComponentModel>();
  private closeRightSide = new Subject<boolean>();
  public applyFiltersSubject = new Subject<string>();

  onTreeItemsChange(): Observable<TreeNode[]> {
    return this.treeItemsSubject.asObservable();
  }

  emitTreeItemsChangeEvent(items: TreeNode[]): void {
    this.treeItemsSubject.next(deepClone(items));
  }

  onRightSideNavigatorContentChanged(): Observable<DynamicComponentModel> {
    return this.rightSideNavigatorContent.asObservable();
  }

  renderRightSideNavigator(component: Type<any>, data: any): void {
    const content = new DynamicComponentModel(component, data);
    this.rightSideNavigatorContent.next(content);
  }
  onCloseRightSide(): Observable<boolean> {
    return this.closeRightSide.asObservable();
  }

  emitCloseRightSideEvent(updated?): void {
    this.closeRightSide.next(updated);
  }
}
