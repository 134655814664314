import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'nome-informative-dialog',
  templateUrl: './informative-dialog.component.html',
  styleUrls: ['./informative-dialog.component.scss']
})
export class InformativeDialogComponent {
  get title(): string {
    return this.data.title;
  }
  get message(): string {
    return this.data.message;
  }

  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private dialog: MatDialogRef<InformativeDialogComponent>) {}

  closeDialog(): void {
    this.dialog.close();
  }
}
