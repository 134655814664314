import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FullChipItemsComponent } from '../full-chip-items/full-chip-items.component';

@Component({
  selector: 'nome-custom-chips-list',
  templateUrl: './custom-chips-list.component.html',
  styleUrls: ['./custom-chips-list.component.scss']
})
export class CustomChipsListComponent implements OnInit {
  @Input() list: any[];
  @Input() listLimit: number; // Use 0 for no limit and show all items
  @Input() onlyShowRemainingInDialog = false;
  @Input() additionalItemsDialogTitle: string;
  @Input() additionalItemsDialogSubtitle: string;
  displayList: any[];
  newChip: { id: number; name: string };
  remainingItems: any[];
  constructor(private dialog: MatDialog) {}

  ngOnInit() {
    if (this.listLimit && this.listLimit > 0 && this.list.length > this.listLimit) {
      const displayList = [...this.list.slice(0, this.listLimit)];
      this.remainingItems = [...this.list.slice(this.listLimit, this.list.length)];
      this.newChip = { id: -1, name: `+${this.list.length - this.listLimit}` };
      this.displayList = displayList;
    } else {
      this.displayList = this.list;
    }
  }

  showRemainingItems() {
    this.dialog.open(FullChipItemsComponent, {
      data: {
        title: this.additionalItemsDialogTitle,
        subtitle: this.additionalItemsDialogSubtitle,
        items: this.list,
        remainingItems: this.remainingItems,
        showRemainingOnly: this.onlyShowRemainingInDialog
      },
      panelClass: 'custom-chip-dialog'
    });
  }

  itemTrack(item: any) {
    return item.id;
  }
}
