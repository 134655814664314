import { CardButton } from '../models/card.model';
import { CardActions } from '../models/cardActions.enum';
import { Permissions } from '../models/enums/Permissions.enum';

export const ActionsData: CardButton[] = [
  { title: 'view_content', cardAction: CardActions.VIEW_CONTENT, permission: Permissions.EDIT, revertPermission: true },
  { title: 'edit', cardAction: CardActions.EDIT, permission: Permissions.EDIT },
  { title: 'disable', cardAction: CardActions.DISABLE, permission: Permissions.EDIT },
  { title: 'clone', cardAction: CardActions.CLONE, permission: Permissions.ADD },
  { title: 'delete', cardAction: CardActions.DELETE, permission: Permissions.DELETE },
  { title: 'design_template.action', cardAction: CardActions.DESIGN_TEMPLATE, allowed: true },
  { title: 'bulk_edit', cardAction: CardActions.BulkEdit, permission: Permissions.Bulk },
  { title: 'account_manager.accounts.configure', cardAction: CardActions.CONFIGURE, allowed: true },
  { title: 'account_manager.accounts.trigger-dis', cardAction: CardActions.DIS, allowed: true },
  { title: 'account_manager.account_stage.move_to_staging', cardAction: CardActions.UPDATEMODE, allowed: true },
  { title: 'account_manager.api-config.setup-external-interface', cardAction: CardActions.SETUP_EXTERNAL_INTERFACE, allowed: true },
  { title: 'account_manager.subscriptions.approve', cardAction: CardActions.APPROVE, allowed: true },
  { title: 'account_manager.subscriptions.reject', cardAction: CardActions.REJECT, allowed: true },
  { title: 'account_manager.subscriptions.unsubscribe', cardAction: CardActions.UNSUBSCRIBE, allowed: true },
  { title: 'account_manager.users.send-activation-email', cardAction: CardActions.SEND_ACTIVATION_EMAIL, allowed: true },
  { title: 'account_manager.users.resend-activation-email', cardAction: CardActions.RESEND_ACTIVATION_EMAIL, allowed: true },
  { title: 'account_manager.users.set-password', cardAction: CardActions.SET_PASSWORD, allowed: true },
  { title: 'analytics.checklist_reports.view_evidence', cardAction: CardActions.VIEW_EVIDENCE, allowed: true },
  { title: 'subscription_code.clear_code', cardAction: CardActions.CLEAR_SUBSCRIPTION_CODE, allowed: true },
  { title: 'menu_manager.generate_code', cardAction: CardActions.GENERATE_SUBSCRIPTION_CODE, allowed: true },
  { title: 'publish', cardAction: CardActions.PUBLISH, allowed: true },
  { title: 'checklist.import.label', cardAction: CardActions.IMPORT, allowed: true },
  { title: 'export.label', cardAction: CardActions.EXPORT, allowed: true },
  { title: 'unassign', cardAction: CardActions.UNASSIGN, allowed: true },
  { title: 'enable', cardAction: CardActions.ENABLE, allowed: true },
  { title: 'locations_manager.assign-node', cardAction: CardActions.ASSIGN, allowed: true },
  { title: 'locations_manager.fetch_logs', cardAction: CardActions.FETCH_LOGS, allowed: true },
  { title: 'locations_manager.cancel_fetch_logs', cardAction: CardActions.CANCEL_FETCH_LOGS, allowed: true },
  { title: 'locations_manager.clear_app_data', cardAction: CardActions.CLEAR_APP_DATA, allowed: true },
  { title: 'locations_manager.cancel_clear_app_data', cardAction: CardActions.CANCEL_CLEAR_APP_DATA, allowed: true },
  { title: 'locations_manager.full_sync', cardAction: CardActions.FULL_SYNC, allowed: true },
  { title: 'locations_manager.cancel_full_sync', cardAction: CardActions.CANCEL_FULL_SYNC, allowed: true },
  { title: 'locations_manager.flag_as_failed_to_sync', cardAction: CardActions.FLAG_DEVICE_FAILED_TO_SYNC, allowed: true },
  { title: 'locations_manager.flag_as_ready_to_sync', cardAction: CardActions.FLAG_DEVICE_READY_TO_SYNC, allowed: true },
  { title: 'view_details', cardAction: CardActions.SHOW_DETAILS, allowed: true },
  { title: 'customize', cardAction: CardActions.CUSTOMIZE, allowed: true },
  { title: 'locations_manager.publish_menu', cardAction: CardActions.PUBLISH_MENU, allowed: true },
  { title: 'menu_manager.export_pricing', cardAction: CardActions.EXPORT_PRICING, allowed: true },
  { title: 'inventory_manager.templates.start_order', cardAction: CardActions.ORDER_NOW, allowed: true },
  { title: 'inventory_manager.orders.create_template', cardAction: CardActions.CREATE_TEMPLATE, allowed: true },
  { title: 'inventory_manager.template_creation.replace', cardAction: CardActions.REPLACE, allowed: true },
  { title: 'label_designer.remote_print_template', cardAction: CardActions.REMOTE_PRINT_TEMPLATE, allowed: true },
  { title: 'generate_qr_code', cardAction: CardActions.GENERATE_QR_CODE, allowed: true },
  { title: 'menu_manager.import_customization', cardAction: CardActions.IMPORT_CUSTOMIZATION },
  { title: 'menu_manager.bulk_add', cardAction: CardActions.BULK_ADD },
  { title: 'account_manager.location_subscriptions.move', cardAction: CardActions.MOVE },
  { title: 'inventory_manager.orders.force_approve', cardAction: CardActions.FORCE_APPROVE },
  { title: 'account_manager.users.reset-password', cardAction: CardActions.RESET_PASSWORD },
  { title: 'account_manager.rfid_config.setup_rfid_interface', cardAction: CardActions.SETUP_CONNECTED_CLOUD, allowed: true }
];
