import { TranslateService } from '@ngx-translate/core';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'nome-full-chip-items',
  templateUrl: './full-chip-items.component.html',
  styleUrls: ['./full-chip-items.component.scss']
})
export class FullChipItemsComponent implements OnInit {
  chipsToDisplay: any[];
  constructor(@Inject(MAT_DIALOG_DATA) public data, private dialogRef: MatDialogRef<FullChipItemsComponent>) {}

  ngOnInit() {
    const showRemainingOnly: boolean = this.data.showRemainingOnly;
    const remainingItems: any[] = this.data.remainingItems;
    const fullList: any[] = this.data.items;
    if (showRemainingOnly) {
      this.chipsToDisplay = remainingItems;
    } else {
      this.chipsToDisplay = fullList;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
