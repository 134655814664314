import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonsGeneratorModule } from '../buttons-generator/buttons-generator.module';
import { DialogHeaderComponent } from './components/dialog-header/dialog-header.component';
import { DialogFooterComponent } from './components/dialog-footer/dialog-footer.component';
import { TranslateModule } from '@ngx-translate/core';
import { TitleCaseModule } from '../../../pipes/title-case/title-case.module';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

@NgModule({
  declarations: [DialogHeaderComponent, DialogFooterComponent],
  imports: [CommonModule, TitleCaseModule, ButtonsGeneratorModule, TranslateModule, MatDialogModule, MatIconModule],
  exports: [DialogHeaderComponent, DialogFooterComponent]
})
export class DialogRelatedModule {}
