import { Attribute, Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { LegacyFloatLabelType as FloatLabelType } from '@angular/material/legacy-form-field';
import { MatLegacySelect as MatSelect, MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { arrayIsDefinedAndNotEmpty, defined } from '../../helpers/app.helpers';
import { DropdownOption } from '../../models/dropdown-option.model';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { TranslateOptions } from '../../modules/form-fields/models/translate-options.model';

@Component({
  selector: 'nome-dropdown-with-search',
  templateUrl: './dropdown-with-search.component.html',
  styleUrls: ['./dropdown-with-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownWithSearchComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DropdownWithSearchComponent),
      multi: true
    }
  ]
})
export class DropdownWithSearchComponent implements OnChanges, ControlValueAccessor, Validator {
  @Input() panelClasses?:
    | string
    | string[]
    | Set<string>
    | {
        [key: string]: any;
      };
  @Input('labelHiddenIfOptionSelected') labelHiddenIfOptionSelected: boolean = false;
  @Input('disableOptionCentering') disableOptionCentering: boolean = false;
  @Input('clearSelectionVisible') clearSelectionVisible = false;
  @Input() labelTranslationKey: string;
  @Input() options: DropdownOption[];
  @Input() translateOptions?: TranslateOptions;
  @Input() formSubmitted: boolean;
  @Input() enableAll = false;
  @Input() virtualScrollEnabled = false;
  @Output() searchChanged = new EventEmitter();
  @Output('endOfScrollReached') private endOfScrollReachedEventEmitter = new EventEmitter<void>();
  @Output('selectAllChecked') private selectAllCheckedEventEmitter = new EventEmitter<boolean>();
  @Input() clientSideSearch = true;
  @Input() private handleScrollEvent = false;
  @Input() addZeroOptionToSolveHiddenMatSelectTrigger: boolean = false;
  filteredArray: DropdownOption[];
  form = new UntypedFormGroup({
    searchText: new UntypedFormControl(''),
    selectedOption: new UntypedFormControl()
  });
  control: AbstractControl;
  onChange = (_: any) => {};
  onTouch = () => {};
  @Output('selectionChange') private selectionChangeEventEmitter = new EventEmitter<MatSelectChange | { value: string }>();
  @Output('filterChange') private onFilterChange = new EventEmitter<string>();
  @ViewChild(MatSelect, { static: true }) private matSelect: MatSelect;
  private panel: any;
  private bottomReached = this.onBottomReached.bind(this);
  get formFieldClasses() {
    return {
      'ng-invalid': this.invalidControl,
      'mat-form-field-invalid': this.invalidControl
    };
  }

  get selectClasses() {
    return {
      'ng-touched': this.control?.touched,
      'mat-select-invalid': this.invalidControl
    };
  }

  private get invalidControl(): boolean {
    return (this.control?.touched || this.formSubmitted) && this.control.invalid;
  }

  @Input() multiple: boolean;

  @Input() selectAllVisible: boolean;
  @Input() selectAllTranslationKey = 'select_all';
  selectAll = {
    checked: false,
    indeterminate: false
  };

  @Input() applyButtonVisible: boolean;
  @Input() applyButtonDisabled: boolean = false;
  @Output('applyClicked') private applyClickedEventEmitter = new EventEmitter<(string | number)[]>();

  optionsVisibility: { [prop: number | string]: boolean } = {};

  @Output('panelOpen') private panelOpenEventEmitter = new EventEmitter<any>();
  @Output('panelClose') private panelCloseEventEmitter = new EventEmitter<any>();

  @Input() floatLabel: FloatLabelType;
  @Input() selectTriggerTemplate: TemplateRef<any>;

  public get optionSelected(): boolean {
    var selected = this.form.get('selectedOption').value;
    return this.multiple ? arrayIsDefinedAndNotEmpty(selected) : defined(selected);
  }

  @ViewChild(CdkVirtualScrollViewport) private viewPort: CdkVirtualScrollViewport;
  private selectedIndex: number;

  constructor(@Attribute('required') public required) {}

  ngOnChanges(changes: SimpleChanges): void {
    const optionsChanged = changes['options'] && defined(changes['options'].currentValue);
    if (optionsChanged) {
      this.setFilteredArray(this.options);
      if (!this.virtualScrollEnabled) this.updateOptionsVisibility();
      if (this.selectAllVisible) {
        this.setSelectAllCheckedState(this.form.get('selectedOption').value);
        this.setSelectAllIndeterminateState(this.form.get('selectedOption').value);
      }
    }
  }

  writeValue(obj: any): void {
    this.form.get('selectedOption').setValue(obj);
    if (this.selectAllVisible && defined(this.options)) {
      this.setSelectAllCheckedState(this.form.get('selectedOption').value);
      this.setSelectAllIndeterminateState(this.form.get('selectedOption').value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  validate(control: AbstractControl): ValidationErrors {
    this.control = control;
    return null;
  }

  filter(viewValue: string): void {
    if (this.clientSideSearch) {
      this.filteredArray = this.options.filter((option) => option.viewValue.toLowerCase().indexOf(viewValue.toLowerCase()) !== -1);
      if (!this.virtualScrollEnabled) this.updateOptionsVisibility();
    } else {
      this.searchChanged.emit(viewValue);
    }
  }

  private updateOptionsVisibility(): void {
    this.optionsVisibility = {};

    this.options.forEach((option) => {
      const visible = this.filteredArray.some((visibleOption) => visibleOption.value === option.value);
      this.optionsVisibility[option.value] = visible;
    });
  }

  onBottomReached(): void {
    if (!this.handleScrollEvent) return;
    this.endOfScrollReachedEventEmitter.emit();
  }

  emitOnEnter(viewValue: string) {
    this.onFilterChange.emit(viewValue);
  }

  setSelectedItemIndex(selectedValue: any): void {
    this.selectedIndex = this.options.findIndex((ele: DropdownOption) => ele.value === selectedValue);
  }

  onOpenedChange(opening: boolean): void {
    this.onTouch();
    const selectedOption = this.form.get('selectedOption').value;
    if (opening) {
      if (this.virtualScrollEnabled) {
        this.viewPort.checkViewportSize();
      }

      if (this.selectedIndex) {
        this.scrollToIndex(this.selectedIndex);
      }
      this.panelOpenEventEmitter.emit(selectedOption);
    } else {
      // do not clear search keyword if not client side search
      // user reopen the dropdown with same filtered list and the search keyword therefore should remain present
      if (this.clientSideSearch) this.clearSearchText();
      this.setFilteredArray(this.options);
      if (!this.virtualScrollEnabled) this.updateOptionsVisibility();
      this.panelCloseEventEmitter.emit(selectedOption);
    }
  }

  trackByIndex(index: number): number {
    return index;
  }

  onSelectionChange(event: MatSelectChange) {
    this.onChange(event.value);
    this.emitSelectionChangeEvent(event);
    if (this.selectAllVisible) {
      this.setSelectAllCheckedState(event.value);
      this.setSelectAllIndeterminateState(event.value);
    }
  }

  onSelectAllChange(change: MatCheckboxChange): void {
    const { checked } = change;
    this.selectAll.checked = checked;
    this.selectAll.indeterminate = false; // this is required for the "select all" indeterminate state to work properly
    const optionsValues = this.options.map((option) => option.value);
    this.form.get('selectedOption').setValue(checked ? optionsValues : []);
    this.onChange(checked ? optionsValues : []);
    this.selectAllCheckedEventEmitter.emit(checked);
  }

  private setSelectAllCheckedState(selectedOptions: (string | number)[]): void {
    this.selectAll.checked = this.options.every((option) => selectedOptions?.some((selectedOption) => selectedOption === option.value));
  }

  private setSelectAllIndeterminateState(selectedOptions: (string | number)[]): void {
    const some = this.options.some((option) => selectedOptions?.some((selectedOption) => selectedOption === option.value));
    const notAll = !this.selectAll.checked;
    this.selectAll.indeterminate = some && notAll;
  }

  onApplyButtonClick(): void {
    const selectedOptions = this.form.get('selectedOption').value;
    this.applyClickedEventEmitter.emit(selectedOptions);
    this.matSelect.close();
  }

  isSpace(event: KeyboardEvent): boolean {
    return event.key === ' ';
  }

  private clearSearchText(): void {
    this.form.get('searchText').setValue('');
  }

  private setFilteredArray(options: DropdownOption[]): void {
    this.filteredArray = options;
  }

  private emitSelectionChangeEvent(event: MatSelectChange): void {
    this.selectionChangeEventEmitter.emit(event);
  }

  deselectAll(event?: MatSelectChange, eventEmit = false): void {
    if (this.optionSelected) {
      if (this.multiple) {
        this.form.get('selectedOption').setValue([]);
      } else {
        this.form.get('selectedOption').setValue(null);
        if (this.virtualScrollEnabled && this.selectedIndex > 0) {
          this.selectedIndex = 0;
          this.scrollToIndex(this.selectedIndex);
        }
      }
      if (eventEmit) {
        this.selectionChangeEventEmitter.emit(this.form.get('selectedOption'));
      }
    }
  }

  private scrollToIndex(index: number): void {
    this.viewPort.setRenderedContentOffset(0);
    this.viewPort.scrollToIndex(index);
  }
}
