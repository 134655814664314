import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, NavigationStart, RouteConfigLoadStart, RouteConfigLoadEnd, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import CobrowseIO from 'cobrowse-sdk-js';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { KeycloakService } from 'keycloak-angular';
import { AuthService } from './core/auth/auth.service';
import { ThemeService } from './shared/services/theme.service';
import { SecurityUtilsService } from './shared/services/security-utils.service';
import { LoaderService } from './shared/services/loader.service';
import { CookieService } from './core/auth/cookie.service';
import { CookiesFlags, CookiesValues } from './shared/models/enums/cookies.enum';
import { LocalizationService } from './shared/services/localization/localization.service';
import { IconRegistrationService } from './shared/services/icon-registration.service';
import { LoginService } from './core/services/login.service';
import { CobrowseDialogComponent } from './shared/components/dialogs/cobrowse-dialog/cobrowse-dialog.component';
import { DialogCobrowseService } from './shared/services/dialog-cobrowse.service';
import { defined, definedAndNotEmptyString, PreloadImages } from './shared/helpers/app.helpers';
import { ListToOtherPagesNavigationService } from './shared/services/list-to-other-pages-navigation.service';
import { environment } from '../environments/environment';
import { SnackbarService } from './shared/services/snackbar.service';
import { LocalStorageService } from './core/services/local-storage.service';
import { PreLoaderService } from './shared/services/pre-loader.service';

export let browserRefresh = false;

@Component({
  selector: 'nome-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  title = 'nome';
  isLoaderVisibile: boolean = false;
  COBROWSE_LICENSE_KEY = environment.COBROWSE_LICENSE_KEY;
  assets: string = environment.assets;
  brandImagesAssets: string;
  refreshSubscription: Subscription;
  themeImagePathChangedSubscription: Subscription;

  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private irs: IconRegistrationService,
    private themeService: ThemeService,
    private localizationService: LocalizationService,
    private authService: AuthService,
    private listToOtherPagesNavigationService: ListToOtherPagesNavigationService,
    private securityUtils: SecurityUtilsService,
    private cookieService: CookieService,
    private keycloakService: KeycloakService,
    private loginService: LoginService,
    public dialog: MatDialog,
    private dialogCobrowseService: DialogCobrowseService,
    private snackbarService: SnackbarService,
    private localStorageService: LocalStorageService,
    private preloader: PreLoaderService
  ) {
    this.brandImagesAssets = this.themeService.getbrandImagesAssets();
    const imagesToPreload = [
      `${this.assets}images/Edit.svg`,
      `${this.assets}images/Edit-disabled.svg`,
      `${this.assets}images/transact-images/loader.svg`,
      `${this.assets}images/zy-images/loader.svg`,
      `${this.assets}images/dashboard-image.jpg`
    ];
    PreloadImages(imagesToPreload);

    this.initializeCobrowse();
  }

  ngAfterViewInit() {
    this.preloader.hide();
  }

  ngOnInit(): void {
    this.listToOtherPagesNavigationService.subscribeToRouteChanges();
    this.themeImagePathChangedSubscription = this.themeService.themeImagePathChanged.subscribe((path) => {
      this.brandImagesAssets = path;
    });
    this.refreshSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !this.router.navigated;
      }
      // Uncomment to show loader when loading new modules
      if (event instanceof RouteConfigLoadStart) {
        this.loaderService.showLoader();
      }
      if (event instanceof RouteConfigLoadEnd) {
        this.loaderService.hideLoader();
      }
      if (event instanceof NavigationEnd) {
        // Handle reseller specific login warning message
        // This code needs to be removed once all users under the reseller are using the new URL
        if (!this.router.url.toLowerCase().includes('login')) {
          if (!defined(this.authService.getResellerName())) {
            const accountInfo = this.authService.getItemByKey('tenantName');
            const resellerLoginId = this.authService.getResellerLoginId() != null ? +this.authService.getResellerLoginId() : null;
            if (definedAndNotEmptyString(accountInfo)) {
              this.loginService.validateAccount(accountInfo, resellerLoginId).subscribe((result) => {
                if (result.accountIsValid) {
                  this.authService.setResellerName(result.resellerTenantName);
                  if (this.authService.shouldOpenLoginWarningDialog()) {
                    this.authService.openLoginWarningDialog();
                  }
                }
              });
            }
          } else {
            if (this.authService.shouldOpenLoginWarningDialog()) {
              this.authService.openLoginWarningDialog();
            }
          }
        }

        // Hamdle cookie warning
        if (this.authService.getAuthenticateUsingKeycloakOIDC() && this.cookieService.getCookie(CookiesFlags.cookieConsent) !== CookiesValues.allow) {
          this.keycloakService.isLoggedIn().then((isLoggedIn) => {
            if (isLoggedIn) this.authService.logout();
          });
        }
      }
    });
    this.localizationService.registerLocalesData();
    this.irs.registerIcons();
    this.loaderService.loaderVisibilityChange.subscribe((visible) => {
      setTimeout(() => (this.isLoaderVisibile = visible), 0);
    });

    this.dialogCobrowseService.onShowDialog().subscribe((value) => {
      if (value) {
        this.showCobrowseDialog();
      }
    });

    this.addGoogleMapsScript();

    // if (environment.staging)
    //   this.addTransactAnalyticsScript();
    this.addAppVersionToIndexHTML();
  }

  private initializeCobrowse() {
    CobrowseIO.license = this.COBROWSE_LICENSE_KEY;
    CobrowseIO.registration = false;
  }

  addAppVersionToIndexHTML() {
    document.firstElementChild.setAttribute('appversion', this.securityUtils.secureText(this.authService.getCurrentStoredAppVersion()));
  }

  addTransactAnalyticsScript() {
    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://adm-staging.transact-tech.com/jN/transact_nomeStaging.js';
    document.head.insertBefore(s, document.head.firstChild);
  }

  addGoogleMapsScript() {
    if (!environment.googleAPIKey) {
      alert('Google API key is not set for this environment!');
    } else {
      var gs1 = document.createElement('script');
      gs1.type = 'text/javascript';
      gs1.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleAPIKey}&sensor=false`;
      document.body.after(gs1);

      var gs2 = document.createElement('script');
      gs2.type = 'text/javascript';
      gs2.src = 'https://googlemaps.github.io/v3-utility-library/packages/markerclustererplus/dist/markerclustererplus.min.js';
      document.body.after(gs2);
    }
  }

  private async showCobrowseDialog() {
    this.loaderService.showLoader();
    const code = await this.generateSupportCode();
    this.loaderService.hideLoader();
    if (defined(code)) {
      this.dialog.open(CobrowseDialogComponent, {
        data: { message: 'sharing_message', code }
      });
    } else {
      this.snackbarService.openWarningSnackBar('something-wrong', 'Ok');
    }
  }

  private async generateSupportCode() {
    try {
      // ensure Cobrowse is loaded
      return await CobrowseIO.client().then(async () => {
        CobrowseIO.start({ allowIFrameStart: true });
        // create a code and display it to the user
        return await CobrowseIO.createSessionCode().then((code) => {
          return code;
        });
      });
    } catch (error) {
      console.error('Cobrowse Error' + error);
    }
  }

  ngOnDestroy(): void {
    this.refreshSubscription.unsubscribe();
    this.themeImagePathChangedSubscription.unsubscribe();
  }
}
