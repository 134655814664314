import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ActionTypeEnum, EshaLogEntry, EshaLogItem } from '../../../models/esha-log-entry.model';

@Component({
  selector: 'nome-event-info-dialog',
  templateUrl: './event-info-dialog.component.html',
  styleUrls: ['./event-info-dialog.component.scss']
})
export class EventInfoDialogComponent implements OnInit {
  public ActionTypeEnum = ActionTypeEnum;
  constructor(
    private dialogRef: MatDialogRef<EventInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      item: EshaLogEntry;
      logs: EshaLogItem[];
    }
  ) {}

  ngOnInit() {}

  close() {
    this.dialogRef.close(false);
  }
}
