import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Tenant } from '../../shared/models/tenant.model';
import { LoaderService } from '../../shared/services/loader.service';
import { NavigationService } from '../../shared/services/navigation.service';
import { AuthService } from '../auth/auth.service';
import { LoginService } from '../services/login.service';
import { RoleService } from '../services/role.service';
import { LocalizationService } from '../../shared/services/localization/localization.service';
import { ThemeService } from './../../shared/services/theme.service';
import { UtilService } from '../../shared/services/util.service';
import { forkJoin, of, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiResponseCodeEnum } from './../../shared/models/enums/ApiResponseCode.enum';
import { definedAndNotEmptyString } from './../../shared/helpers/app.helpers';
import { PunchOutModeEnum } from '../../shared/models/enums/punch-out-mode.enum';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { InformativeDialogComponent } from '../../shared/components/dialogs/informative-dialog/informative-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { AccountManagerService } from '../../modules/account-manager/services/account-manager.service';

@Component({
  selector: 'nome-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild('recaptcha', { static: false }) recaptchaElement: ElementRef;
  brandImagesAssets: string;
  formGroup: UntypedFormGroup;
  accountId: number;
  message: string;
  // availableObjects;
  showLoginPage = false;
  resellerLogoURL: string;
  brandLogoURL: string;
  // filteredTenants: Tenant[] = [];
  // allTenants: Tenant[] = [];
  // selectedTenant: Tenant = null;
  accountName: string;
  isLoading = false;
  isReseller: boolean;
  displayCaptcha: boolean = false;
  isCaptchaRequired: boolean = false;
  recaptchaResponse: string = '';
  isRecaptchaRendered: boolean = false;
  // showContent: boolean = false;
  apiStatusSubscription: Subscription;
  blockedUser: boolean = false;
  blockedEmail: string = '';
  leftAttempts: number = 0;
  failedAttempts: number = 0;
  preservedURL: string;

  resellerLoginId?: number = null;
  punchoutSession: boolean;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private roleService: RoleService,
    private navigationService: NavigationService,
    private loaderService: LoaderService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private themeService: ThemeService,
    public localizationService: LocalizationService,
    public utilService: UtilService,
    private changeDetectorRef: ChangeDetectorRef,
    private matDialog: MatDialog,
    private translateService: TranslateService,
    private accountManagerService: AccountManagerService
  ) {
    this.getBrandInformation();
    this.formGroup = new UntypedFormGroup({
      username: new UntypedFormControl(null, [Validators.required, Validators.email]),
      password: new UntypedFormControl(null, [Validators.required, Validators.minLength(3)])
    });

    //Get Reseller login Id
    let loginId = this.authService.getResellerLoginId();
    this.resellerLoginId = loginId != null ? +this.authService.getResellerLoginId() : null;
    this.punchoutSession = definedAndNotEmptyString(this.route.snapshot.queryParamMap.get('punchoutSession'));

    forkJoin([
      //Check if account has valid branding information
      this.loginService.getBrandingInfo(this.accountName, this.resellerLoginId).pipe(
        map((result) => {
          if (result?.brandsInfo?.length > 0 && result.brandsInfo[0].brandLogoURL && result.resellerLogoURL) {
            this.resellerLogoURL = result.resellerLogoURL;
            this.brandLogoURL = result.brandsInfo[0].brandLogoURL;
          } else {
            this.resellerLogoURL = this.brandLogoURL = undefined;
          }
          return result;
        }),
        catchError((e) => {
          return of(e);
        })
      )
    ]).subscribe();
    this.authService.setZYSupportChatFeature();
  }

  getBrandInformation() {
    this.brandImagesAssets = this.themeService.getbrandImagesAssets();
  }

  ngOnInit() {
    if (this.authService.getAuthenticateUsingKeycloakOIDC()) {
      this.authService.clearStorageAndFirstLoad();
      this.authService.redirectToEmailValidation();
      return;
    }
    this.loginService.clearData();
    this.setBackgroundColor();
    this.navigationService.setBlnDisplayBreadCrumb(false);
    this.accountName = this.authService.getTenantName();

    // Check the Accout Info Variable
    const accountInfoValue = this.route.snapshot.queryParamMap.get('accountInfo');
    if (accountInfoValue) {
      this.accountName = accountInfoValue;

      forkJoin([
        //First we should check if the Account Name or Account Code already exists in our DB
        this.loginService.validateAccount(this.accountName, this.resellerLoginId).pipe(
          map((result) => {
            if (result.accountIsValid) {
              this.authService.setResellerName(result.resellerTenantName);
              if (this.authService.shouldOpenLoginWarningDialog()) {
                this.authService.redirectToAccountSelection();
                this.authService.openLoginWarningDialog();
                return;
              }

              this.accountName = result.accountName;
              this.blockedUser = false;
              this.message = null;
              this.formGroup.reset();
              this.formGroup.get('username').enable();
              this.formGroup.get('password').enable();
              this.authService.setTenantName(this.accountName);
            }
            return result;
          }),
          catchError((e) => {
            this.authService.redirectToAccountSelection();
            return of(e);
          })
        )
      ]).subscribe();
    } else {
      this.authService.clearStorageAndFirstLoad();
      this.authService.redirectToAccountSelection();
    }

    // this.apiStatusSubscription = this.utilService.isAPIAlive().subscribe(
    //   (res) => {
    //     // called to trigger the  updated version update
    //   },
    //   (err) => {
    //     console.log(err);
    //   }
    // );
  }

  setBackgroundColor() {
    this.themeService.setBackgroundColor();
  }
  unsetBackgroundColor() {
    this.themeService.unsetBackgroundColor();
  }
  ngOnDestroy() {
    this.unsetBackgroundColor();
    this.navigationService.setBlnDisplayBreadCrumb(true);
  }
  submitForm(): void {
    const password = this.formGroup.getRawValue().password;
    const email = this.formGroup.getRawValue().username;
    this.roleService.clearUserRoles();
    //TODO: AccountId should be collected from dropdown value
    //Set the current authorized routes in auth service
    this.loginService.login(email, password, this.accountId, this.accountName, null, this.resellerLoginId).subscribe(
      (response) => {
        //handle punchout flow
        if (this.punchoutSession) {
          this.authService.setPunchoutSession(true);
          const punchOutMode = this.route.snapshot.queryParamMap.get('punchOutMode') as PunchOutModeEnum;
          this.authService.setPunchOutMode(punchOutMode);
        }

        // get modules and permissions
        this.accountManagerService.getUserModules(response.results.accountId).subscribe((modules) => {
          this.authService.setCurrentUserModules(modules);
          // Role service related info
          this.roleService.setUserAuthorizedModules(this.authService.getCurrentUserModules());

          this.loginService.loginSuccess(response, this.preservedURL);
        });
      },
      (results) => {
        // code 1200 msg: Login failed. Invalid credentials.
        // code 1243 msg: The user is locked on portal.
        this.message = results.message;
        this.blockedUser = results.code == ApiResponseCodeEnum.USER_BLOCKED_CAN_NOT_LOGIN || (results.data && results.data.isPortalLoginLocked);
        if (this.blockedUser) {
          this.blockedEmail = email;
          this.formGroup.get('username').disable();
          this.formGroup.get('password').disable();
        }
        this.displayCaptcha =
          results.code == ApiResponseCodeEnum.LOGIN_INVALID_CREDENTIALS &&
          results.data.maxLoginRetries - results.data.numberOfFailedLoginAttempts <= 2 &&
          !results.data.isPortalLoginLocked &&
          results.data.maxLoginRetries > 0;
        if (results.data && results.data.maxLoginRetries != 0 && results.data.maxLoginRetries != null && results.data.numberOfFailedLoginAttempts != null) {
          this.leftAttempts = results.data.maxLoginRetries - results.data.numberOfFailedLoginAttempts;
          this.failedAttempts = results.data.numberOfFailedLoginAttempts;
        } else {
          this.failedAttempts = 0;
          this.leftAttempts = 0;
        }
        if (this.displayCaptcha) {
          if (this.isRecaptchaRendered) {
            this.resetReCaptcha();
          } else this.addRecaptchaScript();
        }
      }
    );
  }

  returnToAccountSelection() {
    this.authService.redirectToAccountSelection();
  }

  forgotPassword() {
    this.router.navigate(['/forgotpassword'], { queryParams: { account: btoa(this.accountName) } });

    // this.router.navigate(['/forgotpassword'], { queryParams: { id: btoa(this.accountId.toString()) } });
  }

  displayFn(tenant: Tenant) {
    if (tenant) {
      return tenant.name;
    }
  }
  addRecaptchaScript() {
    this.changeDetectorRef.detectChanges();
    this.isRecaptchaRendered = true;
    this.isCaptchaRequired = true;
    window['grecaptchaCallback'] = () => {
      this.renderReCaptcha();
    };

    (function (d, s, id, obj) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        obj.renderReCaptcha();
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'recaptcha-jssdk', this);
  }

  renderReCaptcha() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      sitekey: environment.captchaKey,
      callback: (response) => {
        this.recaptchaResponse = response;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  resetReCaptcha() {
    this.recaptchaResponse = '';
    window['grecaptcha'].reset(this.recaptchaElement.nativeElement);
  }
}
